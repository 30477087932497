<template>
    <div class="header">

        <time-tracker></time-tracker>
        <router-link :to="{ name: 'home' }" class="header__logo">
            <img src="/img/logo.svg" alt="" />
        </router-link>
        <div class="header__group">
            <!-- <div class="header__item header__item_notifications">
                <button class="header__head active">
                    <svg class="icon icon-notification">
                        <use
                            xlink:href="/img/sprite.svg#icon-notification"
                        ></use>
                    </svg>
                </button>
                <div class="header__body">
                    <div class="header__notifications">
                        <div class="header__notification">
                            <div class="header__icon">
                                <img src="img/paper.svg" alt="" />
                            </div>
                            <div class="header__details">
                                <div class="header__info">
                                    Wrapped Bitcoin is now listed on Unity
                                    Exchange
                                </div>
                                <div class="header__line">
                                    <div class="header__time">24m ago</div>
                                    <div class="header__status"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <router-link
                        :to="{ name: 'inbox' }"
                        class="header__btn btn btn_purple btn_wide"
                    >
                        Zobraziť všetky notifikácie
                    </router-link>
                </div>
            </div> -->
            <div class="header__item header__item_logout">
                <button class="header__head" @click="logout">
                    <svg class="icon icon-arrow-down-square">
                        <use xlink:href="/img/sprite.svg#icon-logout"></use>
                    </svg>
                </button>
            </div>
        </div>
        <div class="header__user">
            <avatar :name="user.name"></avatar>
        </div>
        <button
            class="header__toggle"
            :class="{ active: isSidebarActive }"
            @click="toggleIsActive()"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
            >
                <path d="M22 12H3" stroke="#11142d"></path>
                <g stroke="#808191">
                    <path d="M22 4H13"></path>
                    <path opacity=".301" d="M22 20H13"></path>
                </g>
                <path d="M7 7l-5 5 5 5" stroke="#11142d"></path>
            </svg>
        </button>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import TimeTracker from "../TimeTracker/TimeTracker";
export default {
    components: {TimeTracker},
    data() {
        return {
            user: this.$store.state.auth.user,
        };
    },
    computed: {
        currentRouteName() {
            return this.$route.meta.title;
        },
        isSidebarActive() {
            return this.$store.state.base.sidebarActive;
        },
    },
    methods: {
        ...mapActions({
            signOut: "auth/logout",
            toggleIsActive: "base/setSidebarActive",
        }),
        async logout() {
            await this.$axios.post("/api/logout").then(({ data }) => {
                this.signOut();
                this.$router.push({ name: "login" });
            });
        },
    },
};
</script>
