<template>
    <form ref="form" @submit.prevent="handleSubmit()" v-if="!loading">
        <row>
            <column :type="6">
                <card title="Názov projektu">
                    <div class="form-wrap">
                        <div class="form-item form-item--full">
                            <input
                                type="text"
                                id="name"
                                class="input"
                                v-model="formTitleCapitalize"
                                required
                            />
                        </div>
                    </div>
                </card>
            </column>
            <column :type="6">
                <card title="Farba projektu">
                    <div class="form-wrap">
                        <div class="form-item form-item--full">
                            <input
                                type="color"
                                id="color"
                                class="input"
                                v-model="form.color"
                            />
                        </div>
                    </div>
                </card>
            </column>
            <column :type="6">
                <card title="Klient">
                    <div class="form-wrap">
                        <div class="form-item form-item--full">
                            <input
                                type="text"
                                class="input"
                                placeholder="Vyhľadávanie klienta"
                                autocomplete="off"
                                v-model="filterClientsQuery"
                            />
                        </div>
                        <div
                            class="form-item form-item--full"
                            v-for="client in resultFilterClients"
                            :key="client.id"
                        >
                            <label class="checkbox">
                                <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    v-model="form.client"
                                    :true-value="client.id"
                                    false-value=""
                                />
                                <span class="checkbox__in">
                                    <span class="checkbox__tick"></span>
                                    <span class="checkbox__text">{{
                                        client.title
                                    }}</span>
                                </span>
                            </label>
                        </div>
                    </div>
                </card>
            </column>
            <column :type="6">
                <card title="Developeri">
                    <div class="form-wrap">
                        <div class="form-item form-item--full">
                            <input
                                type="text"
                                class="input"
                                placeholder="Vyhľadávanie developera"
                                autocomplete="off"
                                v-model="filterDevelopersQuery"
                            />
                        </div>
                        <div
                            class="form-item form-item--full"
                            v-for="developer in resultFilterDevelopers"
                            :key="developer.id"
                        >
                            <label class="checkbox">
                                <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    v-model="form.developers"
                                    :value="developer.id"
                                />
                                <span class="checkbox__in">
                                    <span class="checkbox__tick"></span>
                                    <span class="checkbox__text">{{
                                        developer.name
                                    }}</span>
                                </span>
                            </label>
                        </div>
                    </div>
                </card>
            </column>
            <column>
                <card>
                    <div class="form-wrap">
                        <div class="form-buttons">
                            <button class="btn btn_purple" :disabled="sending">
                                <span class="loader" v-if="sending"></span>
                                <span v-else>Uložiť</span>
                            </button>
                        </div>
                    </div>
                </card>
            </column>
        </row>
    </form>
    <loader v-else></loader>
</template>

<script>
import { formTitleCapitalize } from "../../mixins/index.js";
import eventBus from "../../eventBus";

export default {
    mixins: [formTitleCapitalize],
    data() {
        return {
            loading: true,
            sending: false,
            id: null,
            clients: [],
            developers: [],
            form: {
                title: "",
                color: "#bada55",
                client: "",
                developers: [],
            },
            filterClientsQuery: "",
            filterDevelopersQuery: "",
        };
    },
    computed: {
        resultFilterClients() {
            if (this.filterClientsQuery) {
                return this.clients.filter((item) => {
                    return this.filterClientsQuery
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.title.toLowerCase().includes(v));
                });
            } else {
                return this.clients;
            }
        },
        resultFilterDevelopers() {
            if (this.filterDevelopersQuery) {
                return this.developers.filter((item) => {
                    return this.filterDevelopersQuery
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.name.toLowerCase().includes(v));
                });
            } else {
                return this.developers;
            }
        },
    },
    mounted() {
        eventBus.$on('clients', () => { this.fetchClients() });
        eventBus.$on('users', () => { this.fetchDevelopers() });
        eventBus.$on('projects', () => { this.fetchDevelopers() });
    },
    beforeUnmount() {
        eventBus.$off('clients');
        eventBus.$off('users');
        eventBus.$off('projects');
    },
    methods: {
        async fetchClients() {
            try {
                const response = await this.$axios.get("/api/clients");
                const responseData = response.data;

                this.clients = responseData.data;
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
        async fetchDevelopers() {
            try {
                const response = await this.$axios.get(
                    "/api/users/list/developers"
                );
                const responseData = response.data;

                this.developers = responseData.data;
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
        async fetchProject() {
            try {
                const response = await this.$axios.get(
                    `/api/projects/${this.id}`
                );
                const responseData = response.data;

                this.form.title = responseData.data.title;
                this.form.color = responseData.data.color;
                if (responseData.data.client) {
                    this.form.client = responseData.data.client.id;
                }
                this.form.developers = [];
                responseData.data.developers.forEach((developer) => {
                    this.form.developers.push(developer.id);
                });
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
        handleSubmit() {
            if (this.id) {
                this.submitEdit();
            } else {
                this.submitCreate();
            }
        },
        submitCreate() {
            this.sending = true;

            let formData = new FormData();

            formData.append("title", this.form.title);
            formData.append("color", this.form.color);
            formData.append("client_id", this.form.client);
            formData.append("users", this.form.developers);

            this.$axios
                .post("/api/projects", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    this.$toast.success("Projekt bol úspešne vytvorený");
                    this.resetForm();
                })
                .catch(() => {
                    this.$toast.error("Projekt sa nepodarilo vytvoriť");
                })
                .then(() => {
                    this.sending = false;
                });
        },
        submitEdit() {
            this.sending = true;

            let formData = new FormData();

            formData.append("_method", "PATCH");
            formData.append("title", this.form.title);
            formData.append("color", this.form.color);
            formData.append("client_id", this.form.client);
            formData.append("users", this.form.developers);

            this.$axios
                .post(`/api/projects/${this.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    this.$toast.success("Projekt bol úspešne upravený");
                    this.fetchProject();
                })
                .catch(() => {
                    this.$toast.error("Projekt sa nepodarilo upraviť");
                })
                .then(() => {
                    this.sending = false;
                });
        },
        resetForm() {
            this.form.title = "";
            this.form.color = "#bada55";
            this.form.client = "";
            this.form.developers = [];
        },
    },
    created() {
        if (this.$route.name === "projects.edit") {
            this.id = this.$route.params.id;
            this.fetchProject();
        }
        this.fetchClients();
        this.fetchDevelopers();
    },
};
</script>
