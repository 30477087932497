<template>
    <form ref="form" @submit.prevent="handleSubmit()" v-if="!loading">
        <row>
            <column :type="6">
                <card title="Osobné udaje">
                    <div class="form-wrap">
                        <div class="form-item">
                            <label for="name">Meno</label>
                            <input
                                type="text"
                                id="name"
                                class="input"
                                v-model="form.name"
                                required
                            />
                        </div>
                        <div class="form-item">
                            <label for="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                class="input"
                                v-model="form.email"
                                required
                            />
                        </div>

                    </div>
                    <!-- <div class="form-wrap">
                        <div class="form-item" v-if="id === userId">
                            <label for="password">Heslo</label>
                            <input
                                type="password"
                                id="password"
                                class="input"
                                v-model="form.password"
                            />
                        </div>
                        <div class="form-item" v-if="id === userId">
                            <label for="passwordConfirm"
                                >Potvrdenie hesla</label
                            >
                            <input
                                type="passwordConfirm"
                                id="passwordConfirm"
                                class="input"
                                v-model="form.passwordConfirm"
                            />
                        </div>
                    </div> -->
                </card>
            </column>
            <column :type="6" v-if="permission === 1">
                <card title="Role používateľa">
                    <div class="form-wrap">
                        <div
                            class="form-item form-item--3"
                            v-for="role in roles"
                            :key="role.id"
                        >
                            <label class="checkbox">
                                <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    v-model="form.roles"
                                    :value="role.id"
                                />
                                <span class="checkbox__in">
                                    <span class="checkbox__tick"></span>
                                    <span class="checkbox__text">{{
                                        role.title
                                    }}</span>
                                </span>
                            </label>
                        </div>
                    </div>
                </card>
            </column>
            <column v-if="showPassChange">
                <card>
                    <div class="form-wrap" >
                        <div >
                            <label for="name">Staré heslo</label>
                            <input
                                type="password"
                                id="old_password"
                                v-model="form.old_password"
                                :class=" errorsMutable.title ? 'input hasError' : 'input'"
                            />
                            <div v-if="errorsMutable.old_password">
                                <span class="error">{{errorsMutable.old_password[0]}}</span>
                            </div>
                            <label for="name">Nové heslo</label>
                            <input
                                type="password"
                                id="new_password"
                                class="input"
                                v-model="form.new_password"
                                @blur="validatePass"

                            />
                            <div v-if="!validPass">
                                <span>Heslo musí obsahovať malé, veľké písmeno, číslo a minimálne 8 znakov.</span>
                            </div>
                            <label for="name">Nové heslo ešte raz</label>
                            <input
                                type="password"
                                id="new_password_confirm"
                                class="input"
                                v-model="form.new_password_confirm"

                            />
                            <div v-if="!passwordsMatch">
                                <span>Heslá musia byť rovnaké</span>
                            </div>
                        </div>
                    </div>
                </card>
            </column>
            <column>
            <card>
                <div class="form-wrap" >
                    <div class="form-buttons">
                        <button class="btn btn_purple" :disabled="sending">
                            <span class="loader" v-if="sending"></span>
                            <span v-else>Uložiť</span>
                        </button>
                    </div>
                </div>
            </card>
        </column>
        </row>
    </form>
    <loader v-else></loader>
</template>

<script>
// TODO: Zapojit heslo na upravovanie
export default {
    data() {
        return {
            loading: true,
            sending: false,
            showPassChange: false,
            id: null,
            verified: false,
            permission: this.$store.state.auth.permission,
            userId: this.$store.state.auth.user.id,
            roles: [],
            errorsMutable: {},
            validPass: true,
            form: {
                name: "",
                email: "",
                roles: [],
                old_password: '',
                new_password: '',
                new_password_confirm: '',
            },
        };
    },
    computed: {
        passwordsMatch() {
            return this.form.new_password === this.form.new_password_confirm
        }
    },
    methods: {
        validatePass() {
            let pass = this.form.new_password;
            let rx = '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){0,})(?!.*\\s).{8,}$';
            let regex = new RegExp(rx);
            this.validPass = regex.test(pass);
        },
        async fetchRoles() {
            try {
                const response = await this.$axios.get("/api/roles");
                const responseData = response.data;
                this.roles = responseData.data;
                this.loading = false;
            } catch (error) {
                this.$toast.error("Nepodarilo sa načítať role");
            }
        },
        async fetchUser() {
            try {
                const response = await this.$axios.get(`/api/users/${this.id}`);
                const responseData = response.data;

                this.form.name = responseData.data.name;
                this.form.email = responseData.data.email;
                this.form.roles = [];
                this.verified = responseData.data.verified;
                responseData.data.roles.forEach((role) => {
                    this.form.roles.push(role.id);
                });
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
        handleSubmit() {
            this.errorsMutable = {};
            if (this.id) {
                this.submitEdit();
            } else {
                this.submitCreate();
            }
        },
        submitCreate() {
            this.sending = true;

            let formData = new FormData();

            formData.append("name", this.form.name);
            formData.append("email", this.form.email);
            formData.append("roles", this.form.roles);

            this.$axios
                .post("/api/users", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    this.$toast.success("Používateľ bol úspešne vytvorený");
                    this.resetForm();
                })
                .catch((error) => {
                    this.errorsMutable = error.response.data.errors;
                    this.$toast.error("Používateľa sa nepodarilo vytvoriť");
                })
                .then(() => {
                    this.sending = false;
                });
        },

        submitEdit() {
            this.sending = true;

            let formData = new FormData();

            formData.append("_method", "PATCH");
            formData.append("name", this.form.name);
            formData.append("email", this.form.email);
            formData.append("roles", this.form.roles);
            if(this.form.old_password !== '' && this.form.new_password !== '' && this.form.new_password === this.form.new_password_confirm) {
                formData.append("old_password", this.form.old_password);
                formData.append("new_password", this.form.new_password);
            }
            this.$axios
                .post(`/api/users/${this.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    this.$toast.success("Používateľ bol úspešne upravený");
                    this.fetchUser();
                })
                .catch((error) => {
                    this.errorsMutable = error.response.data.errors;
                    this.$toast.error("Používateľa sa nepodarilo upraviť");
                })
                .then(() => {
                    this.sending = false;
                });
        },
        resetForm() {
            this.form.name = "";
            this.form.email = "";
            this.form.roles = [];
        },
    },
    created() {
        if (this.$route.name === "users.edit") {
            this.id = this.$route.params.id;
            this.fetchUser();
        }
        if (this.$route.name === "profile.edit") {
            this.id = this.$store.state.auth.user.id;
            this.showPassChange = true;
            this.fetchUser();
        }
        this.fetchRoles();
    },
};
</script>
