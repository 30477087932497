

<template>
    <div :class="isChild ? 'child' : ''">
        <div class='card__title' v-bind:class="{ 'editing': toEdit === comment.id, 'reply': toReply === comment.id}">
            <strong>{{comment.user}}</strong> ({{formatDate(comment.created_at)}})
            <i class="fa fa-pencil"></i>
            <font-awesome-icon
                :icon="['fa','pencil-alt']"
                @click="editComment(comment)"
                v-if="user_id === comment.user_id || is_admin"
            ></font-awesome-icon>
            <font-awesome-icon
                :icon="['fa','reply']"
                @click="replyToComment(comment)"
            ></font-awesome-icon>
            <font-awesome-icon
                :icon="['fa','trash']"
                v-if="user_id === comment.user_id || is_admin"
                @click="deleteComment(comment)"
            ></font-awesome-icon>
            <font-awesome-icon
                :icon="['fa','times']"
                color="red"
                v-if="toEdit === comment.id || toReply === comment.id"
                @click="cancelComment()"
            ></font-awesome-icon>
        </div>
        <p v-if="toEdit !== comment.id" v-html="renderString(comment.comment)"></p>
        <div v-if="(toEdit === comment.id || toReply === comment.id)">
            <div>
                <textarea name="comment" id="comment" v-model="editedComment">toEdit === comment.id ? comment.comment : ''</textarea>
            </div>
            <div>
                <button @click="postComment()" class="btn btn_purple" >Odoslať</button>
            </div>
        </div>

        <task-comment v-for="child in comment.children" :is-child="true" :comment="child" :users="users"></task-comment>
    </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt, faReply, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons'
library.add(faPencilAlt)
library.add(faReply)
library.add(faTrash)
library.add(faTimes)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import eventBus from "../../eventBus";


export default {
    name: "TaskComment",
    components: {FontAwesomeIcon},
    props: {
        comment: {
            type: Object
        },
        isChild: {
            type: Boolean,
            default: false,
        },
        loading: false,
        modalOpen: false,
        users: {
            type: Array
        }

    },
    data() {
        return {
            user_id: null,
            is_admin: false,
            toEdit: null,
            toReply: null,
            editedComment: ''
        }
    },
    watch: {
        editedComment: (val) => {
            eventBus.$emit('commentChanged', val);
        }
    },
    mounted() {
        eventBus.$on('editComment' , (data)=> {
            this.toEdit = data.id;
            this.toReply = null;
        });
        eventBus.$on('replyToComment' , (data)=> {
            this.toReply = data.id;
            this.toEdit = null;
        });

        this.user_id = this.$store.state.auth.user.id;
        this.is_admin = this.$store.state.auth.user.roles.some((item) => item.id === 1);
    },
    methods: {
        formatDate(dateString){
            let options = {  year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            let today  = new Date(dateString);
            return today.toLocaleDateString("sk-SK", options);
        },
        editComment(comment) {

            eventBus.$emit('editComment', comment);
            this.toReply = null;
            this.editedComment = this.toEdit === comment.id ? comment.comment : '';
        },
        replyToComment(comment) {
            eventBus.$emit('replyToComment', comment);
            this.toEdit = null;
        },
        deleteComment(comment) {
            eventBus.$emit('deleteComment', comment);
        },
        postComment() {
            let data = {
                edit_id: this.toEdit,
                reply_id: this.toReply,
            };
            eventBus.$emit('postComment', data);
            this.toEdit = null;
            this.toReply = null;
        },
        keypress(key){
            // BModal
            console.log(key)
            this.modalOpen = true;
        },
        cancelComment(){
            this.toReply = null;
            this.toEdit = null;
            eventBus.$emit('cancelComment');
        },
        renderString(str) {
            if(this.users) {
                for (let i = 0; i < this.users.length; i++) {
                    str = str.replaceAll('@'+this.users[i].name, '<strong>'+this.users[i].name+'</strong>');
                }
            }
            return str;

        }

    }
}
</script>

<style scoped>
.child{
    margin-left: 1em;
}
.editing {
    border: 1px solid orange;
}
.editing::after {
    content: 'Editujete tento komentár'
}
.reply {
    border: 1px solid green;
}
.reply::after {
    content: 'Reagujete na tento komentár'
}
textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}
</style>
