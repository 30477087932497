<template>
    <card v-if="!loading">
        <table-projects :projects="projects" ></table-projects>
    </card>
    <loader v-else></loader>
</template>

<script>
import TableProjects from "../../components/Table/TableProjects.vue";
import eventBus from "../../eventBus";
export default {
    components: { TableProjects },
    data() {
        return {
            id: null,
            loading: true,
            projects: [],
            customer: false,
        };
    },
    methods: {
        async fetchProjects() {
            let apiTarget = "projects";

            if (!this.customer) {
                switch (this.id) {
                    case "1":
                        apiTarget = "projects/list/assigned";
                        break;
                    case "2":
                        apiTarget = "projects/list/unassigned";
                        break;
                    case "3":
                        apiTarget = "projects/list/deleted";
                        break;
                    default:
                        apiTarget = "projects";
                }
            }

            try {
                const response = await this.$axios.get(`/api/${apiTarget}`);
                const responseData = response.data;

                this.projects = responseData.data;
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
    },
    mounted() {
        this.customer = this.$store.state.auth.user.roles.some((item) => item.id === 3);
        eventBus.$on('projects', () => { this.fetchClients() });
        this.id = this.$route.params.id;
        this.fetchProjects();
    },
    beforeUnmount() {
        eventBus.$off('projects');
    },
};
</script>
