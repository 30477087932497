<template>
    <div
        class="avatar"
        :class="type"
        :style="{ backgroundColor: circleColor ? circleColor : '#6c5dd3' }"
    >
        {{ generateLetters }}
    </div>
</template>

<script>
export default {
    props: ["name", "circleColor", "customClass", "type"],
    computed: {
        generateLetters() {
            return this.name
                .split(" ")
                .slice(0, 2)
                .map((x) => x[0].toUpperCase())
                .join("");
        },
    },
};
</script>

<style lang="scss" scoped>
.avatar {
    position: relative;
    font-size: 16px;
    height: 42px;
    line-height: 42px;
    min-width: 42px;
    width: 42px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    color: #fff;

    z-index: 2;

    &.small {
        font-size: 14px;
        height: 38px;
        line-height: 38px;
        min-width: 38px;
        width: 38px;
    }

    &.space {
        margin: 2px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: -2px;
        background: inherit;
        border-radius: inherit;
        opacity: 0.2;
        z-index: -1;
    }
}
</style>
