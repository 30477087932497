<template>
    <div >
        <div v-bind="getRootProps()" class="drop-zone" v-bind:class="{ 'is_dragging': isDragActive}">
            <input v-bind="getInputProps()" />
            <p v-if="isDragActive">Drop...</p>
            <p v-else>Potiahnite súbory sem.</p>
        </div>
        <button class="btn btn_purple " @click.prevent="open">Nahrať manuálne</button>
    </div>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
import eventBus from "../../eventBus";
import axios from 'axios';
export default {
    name: "TaskDropZone",
    setup() {
        function onDrop(acceptFiles, rejectReasons) {
            for (let i = 0; i < acceptFiles.length; i++) {
                uploadFile(acceptFiles[i]);
            }
        }

        function uploadFile(file) {
            const url = '/api/upload-attachment'
            const formData = new FormData(); // pass data as a form
            formData.set('file', file);
            axios({
                method: 'post',
                url: url,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                console.log(response.data)
                eventBus.$emit('new_file', response.data);
            })
            .catch((err) => {
                console.error(err);
            });
        }

        const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

        return {
            getRootProps,
            getInputProps,
            ...rest,
        };
    },
};
</script>
<style>
    .drop-zone {
        border: 1px solid #7f8090;
        padding: 1.5em;
        margin-top: 1em;
        margin-bottom: 1em;
        border-radius: 5px;
        font-size: 22px;

    }
    .is_dragging {
        background: #6c5dd2;
    }
</style>
