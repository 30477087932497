<template>
    <container>
        <page-head></page-head>
        <div class="filter" v-if="$route.name === 'projects.list' && !customer">
            <router-link :to="'/projects/list/' + 0" class="filter__link"
                >Všetky</router-link
            >
            <router-link :to="'/projects/list/' + 1" class="filter__link"
                >Priradené</router-link
            >
            <router-link :to="'/projects/list/' + 2" class="filter__link"
                >Nepriradené</router-link
            >
            <router-link :to="'/projects/list/' + 3" class="filter__link"
                >Vymazané</router-link
            >
        </div>
        <router-view v-slot="{ Component }">
            <transition name="child" mode="out-in">
                <component :is="Component" :key="$route.path" />
            </transition>
        </router-view>
        <div class="floated-buttons" v-if="!customer">
            <router-link
                :to="{ name: 'projects.create' }"
                class="btn btn_purple"
                v-if="
                    $route.name !== 'projects.detail' &&
                    $route.name !== 'projects.create' &&
                    $route.name !== 'projects.edit'
                "
            >
                Nový projekt
            </router-link>
            <router-link
                :to="{
                    name: 'projects.edit',
                    params: { id: $route.params.id },
                }"
                class="btn btn_purple"
                v-if="$route.name === 'projects.detail'"
            >
                Upraviť projekt
            </router-link>
        </div>
    </container>
</template>

<script>
export default {
    data() {
        return {
            customer: false
        }
    },
    mounted() {
        this.customer = this.$store.state.auth.user.roles.some((item) => item.id === 3);
    }
}
</script>
