<template>
    <container>
        <page-head></page-head>
        <div class="filter" v-if="$route.name === 'users.list'">
            <router-link :to="'/users/list/' + 0" class="filter__link"
                >Všetci</router-link
            >
            <router-link :to="'/users/list/' + 1" class="filter__link"
                >Administrátori</router-link
            >
            <router-link :to="'/users/list/' + 2" class="filter__link"
                >Developeri</router-link
            >
            <router-link :to="'/users/list/' + 3" class="filter__link"
                >Zákazníci</router-link
            >
            <router-link :to="'/users/list/' + 4" class="filter__link"
                >Vymazaní</router-link
            >
        </div>
        <router-view v-slot="{ Component }">
            <transition name="child" mode="out-in">
                <component :is="Component" :key="$route.path" />
            </transition>
        </router-view>
        <div class="floated-buttons">
            <router-link
                :to="{ name: 'users.create' }"
                class="btn btn_purple"
                v-if="
                    $route.name !== 'users.detail' &&
                    $route.name !== 'users.create' &&
                    $route.name !== 'users.edit'
                "
            >
                Nový použivateľ
            </router-link>
            <router-link
                :to="{ name: 'users.edit', params: { id: $route.params.id } }"
                class="btn btn_purple"
                v-if="$route.name === 'users.detail' && permission === 1"
            >
                Upraviť používateľa
            </router-link>
        </div>
    </container>
</template>

<script>
export default {
    data() {
        return {
            permission: this.$store.state.auth.permission,
        };
    },
};
</script>
