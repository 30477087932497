<template>
    <div class="dropdown" :class="{ active: show }">
        <button type="button" class="dropdown__head" @click="toggleDropdown()">
            <svg class="icon icon-dots">
                <use xlink:href="/img/sprite.svg#icon-dots"></use>
            </svg>
        </button>
        <transition name="dropdown" mode="out-in">
            <div class="dropdown__body" v-if="show">
                <slot name="methods"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
        };
    },
    methods: {
        toggleDropdown() {
            this.show = !this.show;
        },
        hideDropdown() {
            this.show = false;
        },
        handleDropdownAction() {
            this.hideDropdown();
        },
        close(e) {
            if (!this.$el.contains(e.target)) {
                this.hideDropdown();
            }
        },
    },
    mounted() {
        document.addEventListener("click", this.close);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.close);
    },
};
</script>

<style lang="scss">
.dropdown-enter-active,
.dropdown-leave-active {
    transition: all 0.3s;
}
.dropdown-enter-from,
.dropdown-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}
</style>
