<template>
    <div class="register">
        <card title="Registrácia">
            <h1 v-if="tokenNotValid" style="color: red"> {{msg}}</h1>
            <form
                :model="form"
                ref="form"
                class="form"
                @submit.prevent="handleSubmit()"
            >
                <div class="form-item form-item--full">
                    <input
                        type="email"
                        id="email"
                        class="input"
                        placeholder="Email"
                        v-model="form.email"
                        required
                        readonly
                    />

                </div>
                <div class="form-item form-item--full">
                    <input
                        type="password"
                        id="password"
                        class="input"
                        placeholder="Heslo"
                        v-model="form.password"
                        required
                        @blur="validatePass"
                    />
                    <div v-if="!validPass">
                        <span>Heslo musí obsahovať malé, veľké písmeno, číslo a minimálne 8 znakov.</span>
                    </div>
                </div>
                <div class="form-item form-item--full">
                    <input
                        type="password"
                        id="passwordConfirm"
                        class="input"
                        placeholder="Potvrdenie hesla"
                        v-model="form.passwordConfirm"
                        required
                    />
                    <div v-if="!passwordsMatch">
                        <span>Heslá musia byť rovnaké</span>
                    </div>
                </div>
                <div class="form-buttons">
                    <button class="btn btn_purple btn_wide" :disabled="loading">
                        <span class="loader" v-if="loading"></span>
                        <span v-else>Registrovať</span>
                    </button>
                </div>
            </form>
        </card>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        // var validatePass = (rule, value, callback) => {
        //     if (value === "") {
        //         callback(new Error("Prosím vyplňte heslo"));
        //     } else {
        //         if (this.form.passwordConfirm !== "") {
        //             this.$refs.form.validateField("passwordConfirm");
        //         }
        //         callback();
        //     }
        // };
        // var validatePass2 = (rule, value, callback) => {
        //     if (value === "") {
        //         callback(new Error("Prosím potvrďte heslo"));
        //     } else if (value !== this.form.password) {
        //         callback(new Error("Heslá sa nezhodujú!"));
        //     } else {
        //         callback();
        //     }
        // };
        return {
            loading: false,
            validPass: true,
            tokenNotValid: false,
            msg: '',
            form: {
                password: "",
                passwordConfirm: "",
                email: '',
                token: '',
            },
        };
    },
    computed: {
        passwordsMatch() {
            return this.form.password === this.form.passwordConfirm
        }
    },
    mounted() {
        this.form.email =  this.$route.query.email;
        this.form.token =  this.$route.query.token;

    },

    methods: {
        validatePass() {
            let pass = this.form.password;
            let rx = '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){0,})(?!.*\\s).{8,}$';
            let regex = new RegExp(rx);
            this.validPass = regex.test(pass);
        },
        handleSubmit() {
            this.register();
        },
        register() {
            this.loading = true;

            let formData = new FormData();

            formData.append("token", this.form.token);
            formData.append("email", this.form.email);
            formData.append("password", this.form.password);
            formData.append("password_confirmation", this.form.passwordConfirm);

            this.$axios
                .post(`/api/reset-password`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((data) => {
                    if(data.data.status == true) {
                        this.$toast.success("Dokončenie registrácie bolo úspešné");
                        this.$router.push({ name: "login" });
                    } else {
                        this.tokenNotValid = true;
                        this.msg = data.data.data[0];
                    }

                })
                .catch((data) => {
                    this.$toast.error("Nepodarilo sa dokončiť registráciu");
                })
                .then(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss">
.register {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 100vh;
    max-width: 27rem;
    margin: 0 auto;
    padding: 0 1rem;

    .card__title {
        text-align: center;
        font-size: 1.7rem;
    }
}
</style>
