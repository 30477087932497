import { createApp } from "vue";
import router from "./router/index.js";

import store from "./store/index.js";
import axios from "axios";

import Toaster from "@meforma/vue-toaster";
import VueConfirmPlugin from 'v3confirm/dist/index'

import App from "./App.vue";
import { goBack } from "./mixins/index.js";

import ColorBox from "./components/ColorBox/index.vue";
import Priority from "./components/Priority/index.vue";
import PageHead from "./components/PageHead/index.vue";
import Card from "./components/Card/index.vue";
import Dropdown from "./components/Dropdown/index.vue";
import RoleTag from "./components/RoleTag/index.vue";
import Container from "./components/Container/index.vue";
import Row from "./components/Row/index.vue";
import Column from "./components/Column/index.vue";
import Avatar from "./components/Avatar/index.vue";
import Loader from "./components/Loader/index.vue";
import Tooltip from "./components/Tooltip/index.vue";
import eventBus from "./eventBus";
const app = createApp(App);

app.config.globalProperties.$axios = axios;

axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if ([401, 403, 419].includes(error.response.status)) {
            store.dispatch("auth/logout");
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

app.use(store);
app.use(router);
app.use(eventBus);
app.use(Toaster, {
    position: "bottom-right",
});
app.use(VueConfirmPlugin, {
    root: '#confirm',
    yesText: 'Áno',
    noText: 'Nie',
})


app.mixin(goBack);
app.component("ColorBox", ColorBox);
app.component("Priority", Priority);
app.component("PageHead", PageHead);
app.component("Card", Card);
app.component("Dropdown", Dropdown);
app.component("RoleTag", RoleTag);
app.component("Container", Container);
app.component("Row", Row);
app.component("Column", Column);
app.component("Avatar", Avatar);
app.component("Loader", Loader);
app.component("Tooltip", Tooltip);


router.isReady().then(function () {


    app.mount("#app");
});
