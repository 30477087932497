<template>
    <row v-if="!loading">
        <column :type="email.attachments.length > 0 ? 4 : 6">
            <card :title="'Od'">
                <p>{{ email.from }}</p>
            </card>
        </column>
        <column :type="email.attachments.length > 0 ? 4 : 6">
            <card :title="'Predmet'">
                <p v-if="email.subject"> {{email.subject}}</p>
            </card>
        </column>
        <column :type="4" v-if="email.attachments.length > 0">
            <card :title="'Príloha'">
                <ul>
                    <li v-for="attachment in email.attachments">
                        <a :href="attachment.url">
                            <font-awesome-icon
                                :icon="['fa','file']"
                            ></font-awesome-icon> {{attachment.filename}}
                        </a>
                    </li>
                </ul>
            </card>
        </column>
        <column :type="display_projects ? 6 : 12">
            <card :title="''">
                <p v-if="email.text" v-html="email.text"></p>
            </card>
        </column>
        <column v-if="display_projects" :type="6">
            <card :title="'Project'">
                <div class="form-wrap">
                    <div
                        class="form-item form-item--full"
                        v-for="project in projects"
                        :key="project.id"
                    >
                        <label class="checkbox">
                            <input
                                class="checkbox__input"
                                type="checkbox"
                                v-model="project_id"
                                :true-value="project.id"
                                false-value=""
                            />
                            <span class="checkbox__in">
                                    <span class="checkbox__tick"></span>
                                    <span class="checkbox__text">
                                        {{ project.title }}
                                    </span>
                                </span>
                        </label>
                    </div>

                </div>
            </card>
        </column>

        <div class="floated-buttons" v-if="project_id !== null">
            <button class="btn btn_purple" @click="createTask()">Vytvoriť úlohu</button>
        </div>

    </row>
    <loader v-else></loader>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFile } from '@fortawesome/free-solid-svg-icons'
library.add(faFile)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    name: "EmailComponent",
    components: {FontAwesomeIcon},
    data() {
        return {
            email_id: null,
            loading: true,
            display_projects: false,
            project_id: null,
            email: {
                from: '',
                subject: '',
                text: '',
                attachments: []
            },
            projects: [],
        }
    },
    methods: {
        async fetchEmail() {

            try {
                const response = await this.$axios.get(
                    `/api/emails/get/${this.email_id}`
                );
                const responseData = response.data;

                this.email = responseData.email;
                this.projects = responseData.projects;
                this.display_projects = responseData.display_projects;
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
        createTask() {
            console.log(this.project_id)
            ///emails/convert/{email}
            let formData = new FormData;
            formData.set('project_id', this.project_id);
            this.$axios.post(`/api/emails/convert/${this.email_id}`, formData)
                .then((response) => {
                    let responseData = response.data;
                    if(responseData.status) {
                        this.$toast.success(responseData.data);
                        this.$router.push({name:'tasks.detail', params: { id: responseData.id}});
                    } else {
                        this.$toast.error(responseData.data);
                    }
                });
        }
    },
    mounted() {
        this.email_id = this.id = this.$route.params.id;
        this.fetchEmail();
    }
}
</script>

<style scoped>

</style>
