<template>
    <custom-table :data="projects">
        <template v-slot:head>
            <div
                class="table__cell"
                :class="'table__cell--' + column.class"
                v-for="(column, index) in columns"
                :key="index"
            >
                {{ column.name }}
            </div>
        </template>
        <template v-slot:body>
            <div
                class="table__row"
                v-for="(project, index) in projects"
                :key="project.id"
                :class="{ active: activeTableRow === index }"
            >
                <div class="table__cell table__cell--title">
                    <div
                        class="table__color"
                        :style="{ backgroundColor: project.color }"
                    ></div>
                    <router-link
                        :to="{
                            name: 'projects.detail',
                            params: { id: project.id },
                        }"
                        class="table__title"
                    >
                        {{ project.title }}
                        <small
                            v-if="project.client"
                            :style="{
                                color: project.client.color,
                            }"
                            >{{ project.client.title }}</small
                        >
                    </router-link>
                </div>
                <div class="table__cell table__cell--customers">
                    <tooltip
                        v-for="developer in project.developers"
                        :key="developer.id"
                        :title="developer.name"
                    >
                        <template v-slot:head>
                            <router-link
                                :to="{
                                    name: 'users.detail',
                                    params: { id: developer.id },
                                }"
                            >
                                <avatar
                                    :name="developer.name"
                                    type="small space"
                                ></avatar>
                            </router-link>
                        </template>
                        <template v-slot:body>
                            <p>{{ developer.name }}</p>
                        </template>
                    </tooltip>
                </div>
                <div class="table__cell table__cell--date">
                    <span class="table__text">
                        {{ project.created_at }}
                    </span>
                </div>
                <div class="table__cell table__cell--operations">
                    <dropdown>
                        <template v-slot:methods>
                            <router-link
                                :to="{
                                    name: 'projects.edit',
                                    params: { id: project.id },
                                }"
                                class="dropdown__link"
                                v-if="!customer"
                            >
                                Upraviť
                            </router-link>
                            <router-link
                                :to="{
                                    name: 'projects.detail',
                                    params: { id: project.id },
                                }"
                                class="dropdown__link"
                            >
                                Zobraziť
                            </router-link>
                            <button
                                class="dropdown__link"
                                @click="handleDelete(project.id, index)"
                                v-if="id !== '3' && !customer"
                            >
                                Vymazať
                            </button>
                            <button
                                class="dropdown__link"
                                @click="handleRestore(project.id, index)"
                                v-if="id === '3'  && !customer"
                            >
                                Obnoviť
                            </button>
                        </template>
                    </dropdown>
                </div>
            </div>
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "./index.vue";
import RoleTag from "../RoleTag/index.vue";

export default {
    props: ["projects"],
    components: { CustomTable, RoleTag },
    data() {
        return {
            id: null,
            customer: false,
            columns: [
                { name: "Názov", class: "name" },
                { name: "Developeri", class: "customers" },
                { name: "Vytvorené", class: "date" },
                { name: "Operácie", class: "operations" },
            ],
            activeTableRow: null,
        };
    },
    methods: {
        handleDelete(id, index) {
            this.activeTableRow = index;
            this.$axios
                .delete(`/api/projects/${id}`)
                .then((response) => {
                    this.$toast.success("Projekt bol úspešne vymazaný");
                    this.projects.splice(index, 1);
                })
                .catch((error) => {
                    this.$toast.error("Projekt sa nepodarilo vymazať");
                })
                .then(() => {
                    this.activeTableRow = null;
                });
        },
        handleRestore(id, index) {
            this.activeTableRow = index;

            this.$axios
                .post(`/api/projects/restore/${id}`)
                .then((response) => {
                    this.$toast.success("Projekt bol úspešne obnovený");
                    this.projects.splice(index, 1);
                })
                .catch((error) => {
                    this.$toast.error("Projekt sa nepodarilo obnoviť");
                })
                .then(() => {
                    this.activeTableRow = null;
                });
        },
    },
    created() {
        this.customer = this.$store.state.auth.user.roles.some((item) => item.id === 3);
        this.id = this.$route.params.id;
    },
};
</script>
