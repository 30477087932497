
import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes.js";
import store from "../store/index.js";

import NProgress from "nprogress";

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
    scrollBehavior(_to, _from, savedPosition) {
        return new Promise((resolve, _reject) => {
            setTimeout(() => {
                if (savedPosition) {
                    resolve(savedPosition);
                } else {
                    resolve({ left: 0, top: 0 });
                }
            }, 700);
        });
    },
});

router.beforeEach((to, _from, next) => {
    NProgress.start();
    store
        .dispatch("auth/checkAuthentification")
        .then(() => {
            if (to.meta.middleware == "guest") {
                if (store.state.auth.authenticated) {
                    next({ name: "home" });
                }
                next();
            } else {
                if (store.state.auth.authenticated) {
                    store.dispatch("base/setSidebarActive", { value: false });
                    if (to.meta.permission) {
                        if (
                            Number(store.state.auth.permission) <=
                            to.meta.permission
                        ) {
                            next();
                        } else {
                            next({ name: "home" });
                        }
                    } else {
                        next();
                    }
                } else {
                    next({ name: "login" });
                }
            }
        })
        .catch((error) => {
            console.log(error);
            // next({ name: "login" });
        });
});

router.afterEach((_, __) => {
    NProgress.done();
});

export default router;
