import axios from "axios";
import router from "../../router/index.js";

const state = () => ({
    authenticated: true,
    user: {},
    permission: null,
});

const getters = {
    getAuthenticated(state) {
        return state.authenticated;
    },
    getUser(state) {
        return state.user;
    },
    getPermission(state) {
        return state.permission;
    },
};
const actions = {
    login({ commit }) {
        return axios
            .get("/api/user/data")
            .then(({ data }) => {
                // commit("setUser", data.data);
                // commit("setAuthenticated", true);
                // commit("setPermission", data.data.roles);
                router.push({ name: "home" });
            })
            .catch((error) => {
                console.log("Prihlasenie sa nepodarilo");
                // commit("setUser", {});
                // commit("setAuthenticated", false);
                // commit("setPermission", null);
            });
    },
    logout({ commit }) {
        commit("setUser", {});
        commit("setAuthenticated", false);
    },
    checkAuthentification({ commit }) {
        return axios
            .get("/api/check/authentication")
            .then((response) => {
                commit("setAuthenticated", response.data.status);
                if (response.data.status) {
                    commit("setUser", response.data.data);
                    commit("setPermission", response.data.data.roles);
                } else {
                    commit("setUser", {});
                    commit("setPermission", null);
                }
            })
            .catch((error) => {
                commit("setUser", {});
                commit("setPermission", null);
                commit("setAuthenticated", false);
            });
    },
};
const mutations = {
    setAuthenticated(state, payload) {
        state.authenticated = payload;
    },
    setUser(state, payload) {
        state.user = payload;
    },
    setPermission(state, payload) {
        if (payload && payload.length > 0) {
            payload.sort(function (a, b) {
                return a.id - b.id;
            });
            state.permission = payload[0].id;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
