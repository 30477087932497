<template>
    <card v-if="!loading">
        <table-tasks :tasks="tasks"></table-tasks>
    </card>
    <loader v-else></loader>
</template>

<script>
import TableTasks from "../../components/Table/TableTasks.vue";
import eventBus from "../../eventBus";

export default {
    components: { TableTasks },
    data() {
        return {
            id: null,
            userId: this.$store.state.auth.user.id,
            loading: true,
            tasks: [],
        };
    },
    methods: {
        async fetchTasks() {
            let apiTarget;

            console.log(this.id);

            switch (this.id) {
                case "0":
                    apiTarget = "tasks/list/all/" + this.userId;
                    break;
                case "1":
                    apiTarget = "tasks/list/today/" + this.userId;
                    break;
                case "2":
                    apiTarget = "tasks/list/uncomplete/" + this.userId;
                    break;
                case "3":
                    apiTarget = "tasks/list/complete/" + this.userId;
                    break;
                case "4":
                    apiTarget = "tasks/list/created/" + this.userId;
                    break;
                case "5":
                    apiTarget = "tasks/list/deleted/" + this.userId;
                    break;
                case "today":
                    apiTarget = "tasks/list/today";
                    break;
                case "all":
                    apiTarget = "tasks/list/all";
                    break;
                default:
                    apiTarget = null;
            }

            if (apiTarget !== null) {
                try {
                    const response = await this.$axios.get(`/api/${apiTarget}`);
                    const responseData = response.data;

                    this.tasks = responseData.data;

                    console.log(this.tasks);

                    this.loading = false;
                } catch (error) {
                    console.log("error");
                }
            } else {
                this.loading = false;
            }
        },
    },
    created() {
        this.id = this.$route.params.id;
        this.fetchTasks();
    },
    mounted() {
        eventBus.$on('tasks', () => { this.fetchTasks() });
    },
    beforeUnmount() {
        eventBus.$off('tasks');
    },
};
</script>
