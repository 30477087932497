<template>
    <row v-if="!loading">
        <column>
            <card :bgColor="client.color">
                <h2>{{ client.title }}</h2>
                <p v-if="client.description">{{ client.description }}</p>
                <p v-if="client.hourly_rate">Hodinová sadzba: <strong>{{ client.hourly_rate }}€</strong></p>
            </card>
        </column>
        <column>
            <card title="Priradení zákazníci">
                <table-users :users="client.customers"></table-users>
            </card>
        </column>
        <column>
            <card title="Priradené projekty">
                <table-projects :projects="client.projects"></table-projects>
            </card>
        </column>
    </row>
    <loader v-else></loader>
</template>

<script>
import TableUsers from "../../components/Table/TableUsers.vue";
import TableProjects from "../../components/Table/TableProjects.vue";
import eventBus from "../../eventBus";

export default {
    components: { TableProjects, TableUsers },
    data() {
        return {
            loading: true,
            id: null,
            client: [],
        };
    },
    methods: {
        async fetchClient() {
            try {
                const response = await this.$axios.get(
                    `/api/clients/${this.id}`
                );
                const responseData = response.data;

                this.client = responseData.data;
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
        handleDelete(index, row) {
            console.log(index, row);
        },
    },
    created() {
        this.id = this.$route.params.id;
        this.fetchClient();
    },
    mounted() {
        eventBus.$on('clients', () => { this.fetchClient() });
    },
    beforeUnmount() {
        eventBus.$off('clients');
    },
};
</script>
