<template>
    <router-link :to="{ name: 'dashboard' }" class="sidebar__item">
        <div class="sidebar__icon">
            <svg class="icon icon-home">
                <use xlink:href="/img/sprite.svg#icon-home"></use>
            </svg>
        </div>
        <div class="sidebar__text">Dashboard</div>
    </router-link>
    <router-link :to="{ name: 'tasks' }" class="sidebar__item">
        <div class="sidebar__icon">
            <svg class="icon icon-document">
                <use xlink:href="/img/sprite.svg#icon-document"></use>
            </svg>
        </div>
        <div class="sidebar__text">Úlohy</div>
    </router-link>
    <router-link :to="{ name: 'projects' }" class="sidebar__item">
        <div class="sidebar__icon">
            <svg class="icon icon-chart">
                <use xlink:href="/img/sprite.svg#icon-chart"></use>
            </svg>
        </div>
        <div class="sidebar__text">Projekty</div>
    </router-link>
    <router-link
        :to="{ name: 'clients' }"
        class="sidebar__item"
        v-if="permission === 1 || permission === 2"
    >
        <div class="sidebar__icon">
            <svg class="icon icon-activity">
                <use xlink:href="/img/sprite.svg#icon-activity"></use>
            </svg>
        </div>
        <div class="sidebar__text">Klienti</div>
    </router-link>
    <router-link :to="{ name: 'inbox' }" class="sidebar__item">
        <div class="sidebar__icon">
            <svg class="icon icon-notification">
                <use xlink:href="/img/sprite.svg#icon-notification"></use>
            </svg>
        </div>
        <div class="sidebar__text">Notifikácie <span v-if="notifications > 0" class="badge badge-important">{{ notifications }}</span></div>
    </router-link>
    <router-link
        :to="{ name: 'emails' }"
        class="sidebar__item"
        v-if="permission === 1"
    >
        <div class="sidebar__icon">
            <font-awesome-icon
                class="icon icon-email"
                :icon="['fa','envelope']"
            ></font-awesome-icon>
        </div>
        <div class="sidebar__text">E-mail</div>
    </router-link>
    <router-link
        :to="{ name: 'users' }"
        class="sidebar__item"
        v-if="permission === 1"
    >
        <div class="sidebar__icon">
            <svg class="icon icon-profile">
                <use xlink:href="/img/sprite.svg#icon-profile"></use>
            </svg>
        </div>
        <div class="sidebar__text">Používatelia</div>
    </router-link>
    <router-link :to="{ name: 'profile.edit' }" class="sidebar__item">
        <div class="sidebar__icon">
            <svg class="icon icon-settings">
                <use xlink:href="/img/sprite.svg#icon-settings"></use>
            </svg>
        </div>
        <div class="sidebar__text">Nastavenia</div>
    </router-link>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import eventBus from "../../eventBus";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
library.add(faEnvelope)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default {
    components: {FontAwesomeIcon},
    data() {
        return {
            permission: this.$store.state.auth.permission,
            notifications: 0
        };
    },
    mounted() {
        eventBus.$on('unread_notifications', (data)=>{
            this.notifications = data;
        });
    }
};
</script>
<style>
.badge {
    color: white;
    padding-right: 9px;
    padding-left: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
}
.badge-important{
    background-color: #b94a48;
}
</style>
