export default {
    computed: {
        formTitleCapitalize: {
            get: function () {
                return this.form.title;
            },
            set: function (newTitle) {
                if (newTitle.length < 1) {
                    this.form.title = "";
                    return;
                }
                this.form.title = newTitle.replace(
                    /^./,
                    newTitle[0].toUpperCase()
                );
            },
        },
    },
};
