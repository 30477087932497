<template>
    <row v-if="!loading">
        <column>
            <card :bgColor="project.color">
                <h2>{{ project.title }}</h2>
            </card>
        </column>
        <column :type="6">
            <card title="Klient">
                <p>{{ project.client ? project.client.title : "-" }}</p>
            </card>
        </column>
        <column :type="6">
            <card title="Vytvorené">
                <p>{{ project.created_at }}</p>
            </card>
        </column>
        <column>
            <card title="Priradení developeri">
                <table-project-users :users="project.developers" :project-id="id"></table-project-users>
            </card>
        </column>
        <column>
            <card title="Priradené úlohy">
                <table-tasks :tasks="project.tasks"></table-tasks>
            </card>
        </column>
    </row>
    <loader v-else></loader>
</template>

<script>
import TableTasks from "../../components/Table/TableTasks.vue";
import TableProjectUsers from "../../components/Table/TableProjectUsers";
export default {
    components: { TableProjectUsers, TableTasks },
    data() {
        return {
            loading: true,
            id: null,
            project: [],
        };
    },
    methods: {
        async fetchProject() {
            try {
                const response = await this.$axios.get(
                    `/api/projects/${this.id}`
                );
                const responseData = response.data;

                this.project = responseData.data;
                console.log(this.project);
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
    },
    mounted() {
        this.id = this.$route.params.id;
        this.fetchProject();
        console.log(this.project);
        eventBus.$on('projects', () => { this.fetchProject() });
    },

    beforeUnmount() {
        eventBus.$off('projects');
    },
};

import eventBus from "../../eventBus";
</script>
