import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import createMultiTabState from "vuex-multi-tab-state";

import base from "./modules/base.js";
import auth from "./modules/auth.js";

const store = createStore({
    modules: {
        base,
        auth,
    },
    plugins: [createPersistedState(), createMultiTabState()],
});

export default store;
