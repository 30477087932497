<template>
    <div class="color-box">
        <div
            class="color-box__square"
            :style="{
                background: color,
            }"
        ></div>
        <div class="color-box__title">{{ title }}</div>
    </div>
</template>

<script>
export default {
    props: ["color", "title"],
};
</script>

<style lang="scss" scoped>
.color-box {
    display: flex;
    align-items: center;
    overflow: hidden;

    &__square {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        border-radius: 4px;
    }

    &__title {
        font-size: 13px;
        margin-left: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
</style>
