<template>
    <row v-if="!loading">
        <column>
            <card :title="task.title">

                <p v-html="task.description"></p>
                <button class="btn btn_purple" @click="startTimeTracker()" v-if="!customer">
                    <font-awesome-icon
                        :icon="['fa','clock']"
                    ></font-awesome-icon> Riešim úlohu
                </button>
            </card>
        </column>
        <column :type="4">
            <card title="Priorita">
                <priority
                    :value="task.priority ? task.priority.value : 0"
                    :disabled="true"
                ></priority>
            </card>
        </column>
        <column :type="4">
            <card title="Stav">
                <p>{{ task.status ? task.status.title : "-" }}</p>
            </card>
        </column>
        <column :type="4">
            <card title="Deadline">
                <p>{{ task.deadline_at ? task.deadline_at : "-" }}</p>
            </card>
        </column>
        <column :type="4">
            <card title="Typ">
                <p>{{ task.task_type }}</p>
            </card>
        </column>
        <column :type="4">
            <card title="Projekt">
                <p>{{ task.project ? task.project.title : "-" }}</p>
            </card>
        </column>
        <column :type="4">
            <card title="Vytvoril">
                <p v-if="task.created_by">
                    {{ task.created_by.name }}
                    <small>({{ task.created_at }})</small>
                </p>
                <p v-else>-</p>
            </card>
        </column>
        <column>
            <card title="Priradení developeri">
                <table-users :users="task.developers"></table-users>
            </card>
        </column>
        <column>
            <card title="Strávený čas">
                <table-trackers :trackers="task.time_trackers"></table-trackers>
            </card>
        </column>
        <column>
            <card title="Komentáre">
                <task-comments :task-id="id" ></task-comments>
            </card>
        </column>
    </row>
    <loader v-else></loader>
</template>

<script>
import TableUsers from "../../components/Table/TableUsers.vue";
import TableTrackers from "../../components/Table/TableTrackers";
import TaskComments from "./TaskComments";
import eventBus from "../../eventBus";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faClock } from '@fortawesome/free-solid-svg-icons'
library.add( faClock)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


export default {
    components: { TableUsers, TaskComments, FontAwesomeIcon, TableTrackers },
    data() {
        return {
            loading: true,
            id: null,
            task: [],
            customer: true
        };
    },
    methods: {
        async fetchTask() {
            try {
                const response = await this.$axios.get(`/api/tasks/${this.id}`);
                const responseData = response.data;

                this.task = responseData.data;
                console.log(response.data);
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
        startTimeTracker() {
            this.$axios.post(`/api/time-tracker/task/${this.id}/create`)
            .then( (response) => {
                if(response.data.success) {
                    eventBus.$emit('newTimeTracker', response.data.tracker);
                }
            })

        }
    },
    mounted() {
        this.customer = this.$store.state.auth.user.roles.some((item) => item.id === 3);
        this.id = this.$route.params.id;
        this.fetchTask();
    },
};
</script>
