<template>
    <div class="user_names" v-bind:class=" {'hidden' : !display}">
        <ul>
            <li v-for="user in users" @click="userClicked(user.name)">{{user.name}}</li>
        </ul>
    </div>
</template>

<script>
import eventBus from "../../eventBus";

export default {
    name: "UserNameAutocomplete",
    props: {
        users: {
            type: Array
        }
    },
    data(){
        return {
            display: false,
        }
    },
    mounted() {
        eventBus.$on('atPressed', ()=> {
            let rect = this.setElementPosition();
            document.querySelector('.user_names').style.top = rect.y + 150 + 'px';
            this.display = true;
        });

        window.addEventListener('scroll', ()=>{
            if(this.display) {
                let rect = this.setElementPosition();
                document.querySelector('.user_names').style.top = rect.y + 150 + 'px';
            }
        });

    },
    methods: {
        setElementPosition() {
            return document.querySelector('#comment').getBoundingClientRect();
        },
        userClicked(userName) {
            eventBus.$emit('userSelected', userName);
            this.display = false;
        }
    }
}
</script>

<style scoped>
.user_names {
    position: fixed;
    top: 50%;
    background: white;
    border: 1px solid gray;
    border-radius: 5px;
}
.user_names > ul > li {
    z-index: 99;
    border: 1px solid #4a5568;
    border-radius: 15px;
    background-color: white;
    box-shadow: gray;
    padding: 5px;
    margin: 5px;
    display: inline-block;
    cursor: pointer;
}
.hidden {
    display: none;
}
</style>
