<template>
    <container>
        <page-head></page-head>
        <div
            class="filter"
            v-if="
                $route.name === 'tasks.list' ||
                $route.name === 'tasks.list.admin'
            "
        >
            <router-link :to="'/tasks/list/' + 0" class="filter__link"
                >Prehľad</router-link
            >
            <router-link :to="'/tasks/list/' + 1" class="filter__link"
                >Dnešné</router-link
            >
            <router-link :to="'/tasks/list/' + 2" class="filter__link"
                >Nedokončené</router-link
            >
            <router-link :to="'/tasks/list/' + 3" class="filter__link"
                >Dokončené</router-link
            >
            <router-link :to="'/tasks/list/' + 4" class="filter__link"
                >Zadané</router-link
            >
            <router-link :to="'/tasks/list/' + 5" class="filter__link"
                >Vymazané</router-link
            >
        </div>
        <div
            class="filter"
            v-if="
                ($route.name === 'tasks.list' ||
                    $route.name === 'tasks.list.admin') &&
                permission === 1
            "
        >
            <router-link :to="'/tasks/list/admin/all'" class="filter__link"
                >Všetky úlohy</router-link
            >
            <router-link :to="'/tasks/list/admin/today'" class="filter__link"
                >Všetky dnešné úlohy</router-link
            >
        </div>
        <router-view v-slot="{ Component }">
            <transition name="child" mode="out-in">
                <component :is="Component" :key="$route.path" />
            </transition>
        </router-view>
        <div class="floated-buttons">
            <router-link
                :to="{ name: 'tasks.create' }"
                class="btn btn_purple"
                v-if="
                    $route.name !== 'tasks.detail' &&
                    $route.name !== 'tasks.create' &&
                    $route.name !== 'tasks.edit'
                "
            >
                Nová úloha
            </router-link>
            <router-link
                :to="{
                    name: 'tasks.edit',
                    params: { id: $route.params.id },
                }"
                class="btn btn_purple"
                v-if="$route.name === 'tasks.detail'"
            >
                Upraviť úlohu
            </router-link>
        </div>
    </container>
</template>

<script>
// TODO: pridanie novej ulohy mne + pridanie novej ulohy niekomu
export default {
    data() {
        return {
            permission: this.$store.state.auth.permission,
        };
    },
};
</script>
