<template>
    <card v-if="!loading">
        <table-users :users="users"></table-users>
    </card>
    <loader v-else></loader>
</template>

<script>
import TableUsers from "../../components/Table/TableUsers.vue";
import eventBus from "../../eventBus";

export default {
    components: { TableUsers },
    data() {
        return {
            id: null,
            loading: true,
            users: [],
        };
    },
    methods: {
        async fetchUsers() {
            let apiTarget;

            switch (this.id) {
                case "1":
                    apiTarget = "users/list/admins";
                    break;
                case "2":
                    apiTarget = "users/list/developers";
                    break;
                case "3":
                    apiTarget = "users/list/customers";
                    break;
                case "4":
                    apiTarget = "users/list/deleted";
                    break;
                default:
                    apiTarget = "users";
            }

            try {
                const response = await this.$axios.get(`/api/${apiTarget}`);
                const responseData = response.data;

                this.users = responseData.data;
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
    },
    created() {
        this.id = this.$route.params.id;
        this.fetchUsers();
    },
    mounted() {
        eventBus.$on('users', () => { this.fetchUsers() });
    },
    beforeUnmount() {
        eventBus.$off('users');
    },
};
</script>
