<template>
    <div class="card" :style="{ backgroundColor: bgColor, color: getContrast }">
        <div v-if="title" class="h6 card__title">{{ title }}</div>
        <slot></slot>
        <div class="card__footer" v-if="hasSlotFooter">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        bgColor: {
            type: String,
        },
    },
    computed: {
        hasSlotFooter() {
            return !!this.$slots.footer;
        },
        getContrast() {
            if (this.bgColor) {
                let hexcolor = this.bgColor;

                // If a leading # is provided, remove it
                if (hexcolor.slice(0, 1) === "#") {
                    hexcolor = hexcolor.slice(1);
                }

                // If a three-character hexcode, make six-character
                if (hexcolor.length === 3) {
                    hexcolor = hexcolor
                        .split("")
                        .map(function (hex) {
                            return hex + hex;
                        })
                        .join("");
                }

                // Convert to RGB value
                var r = parseInt(hexcolor.substr(0, 2), 16);
                var g = parseInt(hexcolor.substr(2, 2), 16);
                var b = parseInt(hexcolor.substr(4, 2), 16);

                // Get YIQ ratio
                var yiq = (r * 299 + g * 587 + b * 114) / 1000;

                // Check contrast
                return yiq >= 128 ? "black" : "white";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    position: relative;
    width: 100%;
    padding: 40px 32px;
    min-height: 100%;
    border-radius: 24px;
    background: #ffffff;
    backface-visibility: hidden;

    &::after {
        content: "";
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: -10px;
        z-index: -1;
        background: inherit;
        border-radius: 24px;
        opacity: 0.5;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: transparent;
        opacity: 1;
        box-shadow: rgba(17, 12, 46, 0.05) 0px 28px 100px 0px;
        border-radius: 24px;
    }

    &__title {
        margin-bottom: 1.5rem;
        font-weight: 600;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
    }
}
</style>
