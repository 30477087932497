<template>
    <form ref="form" @submit.prevent="handleSubmit()" v-if="!loading">
        <row>
            <column type="6">
                <card>
                    <div class="form-wrap">
                        <div class="form-item form-item--full">
                            <label for="name">Názov úlohy</label>
                            <input
                                type="text"
                                id="name"
                                class="input"
                                v-model.trim="formTitleCapitalize"
                                required
                            />
                        </div>
                        <div class="form-item form-item--full">
                            <label for="deadline">Deadline úlohy</label>
                            <flat-pickr
                                id="deadline"
                                class="input"
                                v-model="form.deadline"
                                :config="flatPickrConfig"
                            ></flat-pickr>
                        </div>
                        <div class="form-item form-item--full">
                            <label for="description">Popis úlohy</label>
                            <textarea
                                type="text"
                                id="description"
                                class="input textarea"
                                rows="4"
                                v-model.trim="form.description"
                            ></textarea>
                        </div>
                        <div class="form-item form-item--full">
                            <label for="description">Typ úlohy</label>
                            <select
                                type="text"
                                id="task_type_id"
                                class="input"
                                v-model="form.task_type_id"
                            >
                                <option value="1">Platené</option>
                                <option value="2">Neplatené</option>
                                <option value="3">Fix</option>
                            </select>
                        </div>
                    </div>
                </card>
            </column>
            <column :type="6">
                <card title="Priorita">
                    <div class="form-wrap">
                        <div
                            class="form-item form-item--full"
                            v-for="priority in priorities"
                            :key="priority.id"
                        >
                            <label class="checkbox">
                                <input
                                    class="checkbox__input"
                                    type="radio"
                                    name="priority"
                                    v-model="form.priority_id"
                                    :value="priority.id"
                                />
                                <span class="checkbox__in">
                                    <span class="checkbox__tick"></span>
                                    <span class="checkbox__text">
                                        <priority
                                            :value="priority.value"
                                            :readonly="true"
                                        ></priority>
                                    </span>
                                </span>
                            </label>
                        </div>
                    </div>
                </card>
            </column>
            <column :type="6">
                <card title="Projekt">
                    <div class="form-wrap">
                        <div
                            class="form-item form-item--full"
                            v-for="project in projects"
                            :key="project.id"
                        >
                            <label class="checkbox">
                                <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    v-model="form.project_id"
                                    :true-value="project.id"
                                    false-value=""
                                    @change="
                                        handleProjectChange(project, $event)
                                    "
                                />
                                <span class="checkbox__in">
                                    <span class="checkbox__tick"></span>
                                    <span class="checkbox__text">
                                        <small>
                                            {{
                                                project.client
                                                    ? project.client.title
                                                    : "bez klienta"
                                            }}
                                        </small>
                                        {{ project.title }}
                                    </span>
                                </span>
                            </label>
                        </div>
                    </div>
                </card>
            </column>
            <column :type="6">
                <card title="Developeri">
                    <div
                        class="form-wrap"
                        v-if="
                            form.project_developers &&
                            form.project_developers.length > 0
                        "
                    >
                        <div
                            class="form-item form-item--full"
                            v-for="developer in form.project_developers"
                            :key="developer.id"
                        >
                            <label class="checkbox">
                                <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    v-model="form.developers"
                                    :value="developer.id"
                                />
                                <span class="checkbox__in">
                                    <span class="checkbox__tick"></span>
                                    <span class="checkbox__text">{{
                                            developer.name
                                        }}</span>
                                </span>
                            </label>
                        </div>
                    </div>
                </card>
            </column>
            <column :type="12">
                <card title="Súbory">
                    <div
                        class="form-wrap" v-if="form.attachments">
                        <div
                            class="form-item form-item--full"
                        >
                            <ul>
                                <li v-for="attachment in form.attachments" class="file_row">
                                    <a :href="attachment.url" class="file_link">
                                        <font-awesome-icon
                                            :icon="['fa','file']"
                                        ></font-awesome-icon> {{attachment.filename}}

                                    </a>
                                    <font-awesome-icon
                                        class="trashIcon"
                                        :icon="['fa','trash']"
                                        @click="deleteAttachment(attachment.id)"
                                    ></font-awesome-icon>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <task-drop-zone></task-drop-zone>
                </card>
            </column>
            <column>
                <card>
                    <div class="form-wrap">
                        <div class="form-buttons">
                            <button class="btn btn_purple" :disabled="sending">
                                <span class="loader" v-if="sending"></span>
                                <span v-else>Uložiť</span>
                            </button>
                        </div>
                    </div>
                </card>
            </column>
        </row>
    </form>
    <loader v-else></loader>
</template>

<script>
import { formTitleCapitalize } from "../../mixins/index.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import eventBus from "../../eventBus";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFile, faTrash } from '@fortawesome/free-solid-svg-icons'
library.add(faFile)
library.add(faTrash)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import TaskDropZone from "./TaskDropZone";
import { Slovak } from "flatpickr/dist/l10n/sk.js";
export default {
    mixins: [formTitleCapitalize],
    components: {
        TaskDropZone,
        flatPickr,
        FontAwesomeIcon
    },


    data() {
        return {
            loading: true,
            sending: false,
            customer: false,
            id: null,
            projects: [],
            priorities: [],
            form: {
                title: "",
                deadline: null,
                description: "",
                status_id: 1,
                priority_id: 1,
                created_by_user_id: this.$store.state.auth.user.id,
                project_id: "",
                project_developers: [],
                developers: [],
                task_type_id: 1,
                attachments: [],
            },
            flatPickrConfig: {
                altFormat: "d.m.Y",
                altInput: true,
                dateFormat: "Y-m-d H:i:S",
                defaultDate: "",
                locale: Slovak,
            },
        };
    },

    methods: {
        async fetchProjects() {
            try {
                const response = await this.$axios.get("/api/projects");
                const responseData = response.data;

                this.projects = responseData.data;
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
        async fetchPriorities() {
            try {
                const response = await this.$axios.get("/api/priorities");
                const responseData = response.data;

                this.priorities = responseData.data;
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
        async fetchTask() {
            try {
                const response = await this.$axios.get(`/api/tasks/${this.id}`);
                const responseData = response.data;
                console.log(response);

                this.form.title = responseData.data.title;
                this.form.description = responseData.data.description
                    ? responseData.data.description
                    : "";
                this.form.status_id = responseData.data.status.id;
                this.form.priority_id = responseData.data.priority.id;
                this.form.attachments = responseData.data.attachments;
                console.log(responseData.data.attachments)
                let dateArr = responseData.data.deadline_at.split(".");
                this.form.deadline =
                    dateArr[2] +
                    "-" +
                    dateArr[1] +
                    "-" +
                    dateArr[0] +
                    " 00:00:00";

                if (responseData.data.project) {
                    this.form.project_id = responseData.data.project.id;


                    this.form.project_developers =  responseData.data.project.developers;
                    if(!this.customer && this.form.project_developers[0].name !== 'Moja úloha') {
                        this.form.project_developers.unshift({id: this.$store.state.auth.user.id, name: 'Moja úloha' });
                    }
                    this.form.developers = [];
                    responseData.data.developers.forEach((developer) => {
                        this.form.developers.push(developer.id);
                    });


                }
                this.loading = false;
            } catch (error) {
                console.log(error);
            }
        },
        handleProjectChange(project, e) {
            if (e.target.checked) {
                this.form.project_developers = project.developers;
                if(!this.customer && this.form.project_developers[0].name !== 'Moja úloha') {
                    this.form.project_developers.unshift({id: this.$store.state.auth.user.id, name: 'Moja úloha' });
                }
                if (this.form.developers) {
                    const filteredDevelopers =
                        this.form.project_developers.filter((developer) =>
                            this.form.developers.includes(developer.id)
                        );

                    let formDevelopers = [];
                    filteredDevelopers.forEach((developer) => {
                        formDevelopers.push(developer.id);
                    });

                    this.form.developers = formDevelopers;
                } else {
                    this.form.developers = [];
                }
            } else {
                this.form.project_developers = [];
                this.form.developers = [];
            }
        },
        handleSubmit() {
            if (this.id) {
                this.submitEdit();
            } else {
                this.submitCreate();
            }
        },
        submitCreate() {
            this.sending = true;

            let formData = new FormData();

            formData.append("title", this.form.title);
            formData.append("description", this.form.description);
            formData.append("status_id", this.form.status_id);
            formData.append("priority_id", this.form.priority_id);
            formData.append("created_by_user_id", this.form.created_by_user_id);
            formData.append(
                "deadline_at",
                this.form.deadline ? this.form.deadline : ""
            );
            formData.append("project_id", this.form.project_id);
            formData.append("developers", this.form.developers);
            formData.append("task_type_id", this.form.task_type_id);
            console.log(this.form.attachments)
            for(let i = 0; i < this.form.attachments.length; i++) {
                formData.append("attachments[]", this.form.attachments[i].id);
            }

            this.$axios
                .post("/api/tasks", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((data) => {
                    this.$toast.success("Úloha bola úspešne vytvorená");
                    let responseData =  data.data.data;

                    this.id =responseData[responseData.length-1].id;
                    eventBus.$emit('read_notifications');
                    this.resetForm();
                })
                .catch((error) => {
                    console.log(error)
                    this.$toast.error("Úlohu sa nepodarilo vytvoriť");
                })
                .then(() => {
                    this.sending = false;
                });
        },
        submitEdit() {
            this.sending = true;

            let formData = new FormData();

            formData.append("_method", "PATCH");
            formData.append("title", this.form.title);
            formData.append(
                "description",
                this.form.description ? this.form.description : ""
            );
            formData.append("status_id", this.form.status_id);
            formData.append("priority_id", this.form.priority_id);
            formData.append("task_type_id", this.form.task_type_id);

            formData.append(
                "deadline_at",
                this.form.deadline ? this.form.deadline : ""
            );
            formData.append("project_id", this.form.project_id);
            if (this.form.developers.length <= 0) {
                formData.append("developers", [this.form.created_by_user_id]);
            } else {
                formData.append("developers", this.form.developers);
            }
            console.log(this.form.attachments)
            for(let i = 0; i < this.form.attachments.length; i++) {
                formData.append("attachments[]", this.form.attachments[i].id);
            }
            this.$axios
                .post(`/api/tasks/${this.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    this.$toast.success("Úloha bola úspešne upravená");
                    eventBus.$emit('read_notifications');
                })
                .catch(() => {
                    this.$toast.error("Úlohu sa nepodarilo upraviť");
                })
                .then(() => {
                    this.sending = false;
                });
        },
        deleteAttachment(id) {
            if( confirm('Naozaj chcete odstrániť súbor?') == true) {
                this.$axios
                    .delete(`/api/attachment/${id}`)
                    .then(() => {
                        this.$toast.success("Súbor odstránený");
                        this.form.attachments = this.form.attachments.filter(element => element.id !== id)
                    })
                    .catch(() => {
                        this.$toast.error("Nepodarilo sa odstrániť súbor");
                    })
                    .then(() => {
                        this.sending = false;
                    });
            }

        },

        resetForm() {
            this.form.title = "";
            this.form.description = "";
            this.form.status_id = 1;
            this.form.priority_id = 1;
            this.form.project_id = "";
            this.form.project_developers = [];
            this.form.created_by_user_id = this.$store.state.auth.user.id;
            this.form.deadline = "";
            this.form.developers = [];
            this.form.attachments = [];
        },
    },
    created() {
        if (this.$route.name === "tasks.edit") {
            this.id = this.$route.params.id;
            this.fetchTask();
        }
        this.customer = this.$store.state.auth.user.roles.some((item) => item.id === 3);
        this.fetchProjects();
        this.fetchPriorities();
    },
    mounted() {
        eventBus.$on('tasks', () => { this.fetchTasks() });
        eventBus.$on('new_file', (data) => {

            this.form.attachments.push(data);
        });
        eventBus.$on('priorities', () => { this.fetchPriorities() });
        eventBus.$on('projects', () => { this.fetchDevelopers() });
    },
    beforeUnmount() {
        eventBus.$off('tasks');
        eventBus.$off('priorities');
        eventBus.$off('projects');
    },
};
</script>
<style>
.trashIcon {
    margin-left: 10px;
    cursor: pointer;
}
.file_row {
    color: #7f809e;
}
.file_link {
    color: #7f809e;
}
.file_link:hover {
    color: #11142d;

 }

</style>
