<template>
    <div class="tooltip" :class="{ active: show }">
        <button
            type="button"
            class="tooltip__head"
            @mouseenter="showTooltip()"
            @mouseleave="hideTooltip()"
        >
            <slot name="head"></slot>
        </button>
        <transition name="tooltip" mode="out-in">
            <div class="tooltip__body" v-if="show">
                <slot name="body"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
        };
    },
    methods: {
        showTooltip() {
            this.show = true;
        },
        hideTooltip() {
            this.show = false;
        },
    },
};
</script>

<style lang="scss">
.tooltip-enter-active,
.tooltip-leave-active {
    transition: all 0.3s;
}
.tooltip-enter-from,
.tooltip-leave-to {
    opacity: 0;
}

.tooltip {
    position: relative;
    display: inline-block;

    &__body {
        position: absolute;
        bottom: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        background: #494949;
        color: #fff;
        padding: 5px 10px;
        border-radius: 2px;
        white-space: nowrap;
        text-align: center;

        &::after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: calc(50% - 4px);

            width: 8px;
            height: 8px;
            background: inherit;
            transform: rotate(45deg);
        }
    }
}
</style>
