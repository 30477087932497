<template>
    <div class="page" :class="{ wide: isSidebarSmall }">
        <the-sidebar></the-sidebar>
        <div class="page__content">
            <the-header></the-header>
            <router-view v-slot="{ Component }">
                <transition name="page" mode="out-in">
                    <component :is="Component" :key="$route.name" />
                </transition>
            </router-view>
        </div>
    </div>
</template>

<script>
import TheSidebar from "../components/TheSidebar/index.vue";
import TheHeader from "../components/TheHeader/index.vue";

import { mapActions } from "vuex";

export default {
    components: {
        TheHeader,
        TheSidebar,
    },
    data() {
        return {
            user: this.$store.state.auth.user,
        };
    },
    computed: {
        authenticated() {
            return this.$store.state.auth.authenticated;
        },
        isSidebarSmall() {
            return this.$store.state.base.sidebarSmall;
        },
    },
    methods: {
        ...mapActions({
            signOut: "auth/logout",
        }),
        async logout() {
            await axios.post("/logout").then(({ data }) => {
                this.signOut();
                this.$router.push({ name: "login" });
            });
        },
    },
};
</script>
