<template>
    <transition name="child" mode="out-in">
        <div v-if="!loading.clients">
            <row v-for="client in clients" :key="client.id">
                <column type="6">
                    <card title="Názov klienta" :bgColor="client.color">
                        <h2>{{ client.title }}</h2>
                    </card>
                </column>
                <column type="6">
                    <card title="Priradení zákazníci">
                        <tooltip
                            v-for="customer in client.customers"
                            :key="customer.id"
                            :title="customer.name"
                        >
                            <template v-slot:head>
                                <router-link
                                    :to="{
                                        name: 'users.detail',
                                        params: { id: customer.id },
                                    }"
                                >
                                    <avatar
                                        :name="customer.name"
                                        type="small space"
                                    ></avatar>
                                </router-link>
                            </template>
                            <template v-slot:body>
                                <p>{{ customer.name }}</p>
                            </template>
                        </tooltip>
                    </card>
                </column>
                <column>
                    <card title="Projekty">
                        <table-projects
                            :projects="client.projects"
                        ></table-projects>
                    </card>
                </column>
            </row>
        </div>
        <loader v-else></loader>
    </transition>
</template>

<script>
import TableProjects from "../../components/Table/TableProjects.vue";
import TableClients from "../../components/Table/TableClients.vue";
import eventBus from "../../eventBus";

export default {
    components: { TableProjects, TableClients },
    data() {
        return {
            user: null,
            loading: {
                clients: true,
            },
            clients: [],
        };
    },
    methods: {
        async fetchData() {
            try {
                const response = await this.$axios.get(
                    `/api/clients/list/assigned/${this.user.id}`
                );
                const responseData = response.data;

                this.clients = responseData.data;
                console.log(this.clients);
                this.loading.clients = false;
            } catch (error) {
                console.log("error");
            }
        },
    },
    created() {
        this.user = this.$store.state.auth.user;
        console.log(this.user);
        this.fetchData();
        // this.fetchProjects();
        // this.fetchClients();
    },
    mounted() {
        eventBus.$on('clients', (event)=> {
            this.fetchData();
        });
    },
    beforeUnmount() {
        eventBus.$off('clients');
    },
};
</script>
