<template>
    <div class="table">
        <div class="table__head">
            <div class="table__row">
                <slot name="head"></slot>
            </div>
        </div>
        <div class="table__body" v-if="data && data.length === 0">
            <div class="table__row">
                <div class="table__cell table__cell--full">Žiadne dáta</div>
            </div>
        </div>
        <div class="table__body" v-else>
            <transition-group name="table-item-animation">
                <slot name="body"></slot>
            </transition-group>
        </div>
    </div>
</template>
<script>
export default {
    props: ["data"],
};
</script>

<style lang="scss">
.table {
    $table: &;
    text-align: left;

    &__head {
        font-size: 13px;
        line-height: 1.38462;
        font-weight: 500;
        color: #b2b3bd;

        #{$table}__cell {
            padding-top: 24px;
            padding-bottom: 24px;
        }
    }

    &__row {
        display: flex;

        &.active {
            & > * {
                transition: opacity 0.3s, filter 0.3s;
                opacity: 0.6;
                filter: grayscale(100%);
                pointer-events: none;
            }
        }
    }

    &__cell {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        border-bottom: 1px solid #e4e4e4;
        padding-left: 20px;
        padding-top: 32px;
        padding-bottom: 32px;

        &--full {
            flex-basis: 100%;
            flex-shrink: 0;
        }
        &--name {
            flex-grow: 1;
            width: 30%;
            flex-wrap: nowrap;

            .avatar {
                margin: 0 10px 0 0;
            }
        }
        &--user{
            flex-grow: 1;
            width: 30%;
            flex-wrap: nowrap;
        }
        &--running{
            flex-grow: 1;
            width: 10%;
            flex-wrap: nowrap;
        }
        &--started{
            flex-grow: 1;
            width: 30%;
            flex-wrap: nowrap;
        }
        &--started{
            flex-grow: 1;
            width: 30%;
            flex-wrap: nowrap;
        }
        &--roles {
            flex-grow: 1;
            width: 20%;
        }
        &--email {
            flex-grow: 1;
            width: 25%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &--operations {
            flex-grow: 0;
            width: 5rem;
            justify-content: flex-end;
            padding-right: 20px;
        }
        &--date {
            width: 7rem;
            flex-grow: 0;
        }
        &--title {
            flex-grow: 1;
            width: 30%;
            flex-wrap: nowrap;
        }
        &--customers {
            flex-grow: 1;
            width: 20%;
            // padding-top: 0;
            // padding-bottom: 0;
        }
        &--client {
            width: 15rem;
        }
        &--priority {
            flex-grow: 0;
            flex-shrink: 0;
            width: 7rem;
        }
        &--status {
            flex-grow: 0;
            flex-shrink: 0;
            width: 3rem;
        }
        &--project {
            flex-grow: 1;
            width: 20%;
        }
        &__from {
            flex-grow: 1;
            width: 25%;
        }
        &__date {
            flex-grow: 1;
            width: 15%;
        }
        &__subject {
            flex-grow: 1;
            width: 20%;
        }
        &__text {
            flex-grow: 1;
            width: 25%;
        }
        &__read {
            flex-grow: 1;
            width: 7%;
        }
    }

    &__title {
        font-size: 16px;
        line-height: 1.1875;
        font-weight: 600;
        color: #11142d;
        overflow: hidden;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            color: #6c5dd3;
        }

        small {
            display: block;
        }
    }

    &__description {
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__text {
        color: #808191;
    }

    &__color {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        border-radius: 4px;
        margin-right: 10px;
    }
}

.table-item-animation-enter-active,
.table-item-animation-leave-active {
    transition: all 0.3s;
}
.table-item-animation-enter-from {
    opacity: 0;
}
.table-item-animation-leave-to {
    opacity: 0;
}
</style>
