<template>
    <row v-if="!loading">
        <column :type="6">
            <card title="Osobné udaje">
                <div class="user-card">
                    <div class="user-card__avatar">
                        <avatar :name="user.name"></avatar>
                    </div>
                    <div class="user-card__content">
                        <h3>{{ user.name }}</h3>
                        <p>{{ user.email }}</p>
                    </div>
                    <button class="btn btn_purple" :disabled="sendingVerification" v-if="showSendVerification" @click="sendVerificationMail()">
                        <span class="loader" v-if="sendingVerification"></span>
                        <span v-else>Odoslať email znova</span>
                    </button>
                </div>
            </card>
        </column>
        <column :type="6">
            <card title="Role používateľa">
                <role-tag
                    v-for="role in user.roles"
                    :key="role.id"
                    :title="role.title"
                    :typeId="role.id"
                ></role-tag>
            </card>
        </column>
        <column v-if="isCustomer">
            <card title="Priradení klienti">
                <table-clients :clients="user.clients"></table-clients>
            </card>
        </column>
        <column v-if="isDeveloper">
            <card title="Priradené projekty">
                <table-projects :projects="user.projects"></table-projects>
            </card>
        </column>
        <column>
            <card title="Priradené úlohy">
                <table-tasks :tasks="user.tasks"></table-tasks>
            </card>
        </column>
    </row>
    <loader v-else></loader>
</template>

<script>
import TableClients from "../../components/Table/TableClients.vue";
import TableProjects from "../../components/Table/TableProjects.vue";
import TableTasks from "../../components/Table/TableTasks.vue";
import RoleTag from "../../components/RoleTag/index.vue";
import eventBus from "../../eventBus";

export default {
    components: { TableClients, TableProjects, TableTasks, RoleTag },
    data() {
        return {
            loading: true,
            id: null,
            user: [],
            showSendVerification: false,
            sendingVerification: false,
        };
    },
    computed: {
        isCustomer() {
            const result = this.user.roles.some((item) => item.id === 3);
            return result;
        },
        isDeveloper() {
            const result = this.user.roles.some((item) => item.id === 2);
            return result;
        },
    },
    methods: {
        sendVerificationMail() {
            this.sendingVerification = true;
            let formData = new FormData();
            formData.append("id", this.user.id);

            this.$axios
                .post(`/api/resend-verification/${this.user.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    this.$toast.success("Email bol úspešne odoslaný");
                    this.fetchUser();
                })
                .catch((error) => {
                    this.errorsMutable = error.response.data.errors;
                    this.$toast.error("Email sa nepodarilo odoslať");
                })
                .then(() => {
                    this.sendingVerification = false;
                });
        },
        async fetchUser() {
            try {
                const response = await this.$axios.get(`/api/users/${this.id}`);
                const responseData = response.data;

                this.user = responseData.data;
                if(!this.user.verified) {
                    this.showSendVerification = true;
                }
                console.log(this.user);
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
    },
    created() {
        this.id = this.$route.params.id;

        this.fetchUser();
    },
    mounted() {
        eventBus.$on('users', () => { this.fetchUser() });
    },
    beforeUnmount() {
        eventBus.$off('users');
    },
};
</script>

<style lang="scss" scoped>
.user-card {
    display: flex;
    align-items: center;

    &__avatar {
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 10px;
    }

    &__content {
        flex: 1;
        line-height: 1.3;
    }
}
</style>
