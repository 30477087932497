<template>
    <div class="page__head">
        <div class="page__category h5">eTask</div>
        <div class="page__title h2">{{ currentRouteName }}</div>
    </div>
</template>

<script>
export default {
    computed: {
        currentRouteName() {
            return this.$route.meta.title;
        },
    },
};
</script>
