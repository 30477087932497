// Base
import NotFound from "../pages/NotFound.vue";

// Containers
import Layout from "../containers/Layout.vue";

//Auth
import Login from "../pages/Auth/Login.vue";
import Register from "../pages/Auth/Register.vue";

//Dashborad
import Dashboard from "../pages/Dashboard/Dashboard.vue";

//Inbox
import Inbox from "../pages/Inbox.vue";

// Projects
import Projects from "../pages/Projects/Projects.vue";
import ProjectsList from "../pages/Projects/ProjectsList.vue";
import ProjectShow from "../pages/Projects/ProjectShow.vue";
import ProjectForm from "../pages/Projects/ProjectForm.vue";

// Clients
import Clients from "../pages/Clients/Clients.vue";
import ClientsList from "../pages/Clients/ClientsList.vue";
import ClientShow from "../pages/Clients/ClientShow.vue";
import ClientForm from "../pages/Clients/ClientForm.vue";

// Users
import Users from "../pages/Users/Users.vue";
import UsersList from "../pages/Users/UsersList.vue";
import UserShow from "../pages/Users/UserShow.vue";
import UserForm from "../pages/Users/UserForm.vue";

// Tasks
import Tasks from "../pages/Tasks/Tasks.vue";
import TasksList from "../pages/Tasks/TasksList.vue";
import TaskShow from "../pages/Tasks/TaskShow.vue";
import TaskForm from "../pages/Tasks/TaskForm.vue";
import EmailsList from "../pages/Emails/EmailsList";
import EmailComponent from "../pages/Emails/EmailComponent";
import EmailsDefaultComponent from "../pages/Emails/EmailsDefaultComponent";

export const routes = [
    {
        name: "login",
        path: "/login",
        component: Login,
        meta: {
            middleware: "guest",
            title: "Login",
            key: "login",
        },
    },
    {
        name: "register",
        path: "/register",
        component: Register,
        meta: {
            middleware: "guest",
            title: "Registrácia",
            key: "register",
        },
    },
    {
        name: "home",
        path: "/",
        component: Layout,
        redirect: { name: "dashboard" },
        meta: {
            middleware: "auth",
            title: "Home",
            key: "layout",
        },
        children: [
            {
                name: "dashboard",
                path: "/dashboard",
                component: Dashboard,
                meta: {
                    title: "Dashboard",
                },
            },
            {
                name: "inbox",
                path: "/inbox",
                component: Inbox,
                meta: {
                    title: "Schránka",
                },
            },
            {
                name: "emails",
                path: "/emails/",
                component: EmailsDefaultComponent,
                redirect: { name: "emails.list", params: { id: 0 } },
                meta: {
                    title: "E-mails",
                },
                children: [
                    {
                        name: "emails.list",
                        path: "list",
                        component: EmailsList,
                        meta: {
                            title: "E-mails",
                            permission: 1,
                        },
                    },
                    {
                        name: "emails.get",
                        path: "get/:id",
                        component: EmailComponent,
                        meta: {
                            title: "E-mail",
                            permission: 1,
                        },
                    },
                ]
            },
            {
                name: "tasks",
                path: "/tasks/",
                component: Tasks,
                redirect: { name: "tasks.list", params: { id: 0 } },
                meta: {
                    title: "Úlohy",
                },
                children: [
                    {
                        name: "tasks.list.admin",
                        path: "list/admin/:id",
                        component: TasksList,
                        meta: {
                            title: "Úlohy",
                            permission: 1,
                        },
                    },
                    {
                        name: "tasks.list",
                        path: "list/:id",
                        component: TasksList,
                        meta: {
                            title: "Úlohy",
                        },
                    },
                    {
                        name: "tasks.create",
                        path: "create",
                        component: TaskForm,
                        meta: {
                            title: "Pridanie novej úlohy",
                        },
                    },
                    {
                        name: "tasks.edit",
                        path: "edit/:id",
                        component: TaskForm,
                        props: true,
                        meta: {
                            title: "Úprava úlohy",
                        },
                    },
                    {
                        name: "tasks.detail",
                        path: "detail/:id",
                        component: TaskShow,
                        props: true,
                        meta: {
                            title: "Detail úlohy",
                        },
                    },
                ],
            },
            {
                name: "projects",
                path: "/projects/",
                component: Projects,
                redirect: { name: "projects.list", params: { id: 0 } },
                meta: {
                    title: "Projekty",
                },
                children: [
                    {
                        name: "projects.list",
                        path: "list/:id",
                        component: ProjectsList,
                        meta: {
                            title: "Projekty",
                        },
                    },
                    {
                        name: "projects.create",
                        path: "create",
                        component: ProjectForm,
                        meta: {
                            title: "Pridanie nového projektu",
                        },
                    },
                    {
                        name: "projects.edit",
                        path: "edit/:id",
                        component: ProjectForm,
                        props: true,
                        meta: {
                            title: "Úprava projektu",
                        },
                    },
                    {
                        name: "projects.detail",
                        path: "detail/:id",
                        component: ProjectShow,
                        props: true,
                        meta: {
                            title: "Detail projektu",
                        },
                    },
                ],
            },
            {
                name: "clients",
                path: "/clients/",
                component: Clients,
                redirect: { name: "clients.list", params: { id: 0 } },
                meta: {
                    title: "Klienti",
                    permission: 2,
                },
                children: [
                    {
                        name: "clients.list",
                        path: "list/:id",
                        component: ClientsList,
                        meta: {
                            title: "Klienti",
                        },
                    },
                    {
                        name: "clients.create",
                        path: "create",
                        component: ClientForm,
                        meta: {
                            title: "Pridanie nového klienta",
                        },
                    },
                    {
                        name: "clients.edit",
                        path: "edit/:id",
                        component: ClientForm,
                        props: true,
                        meta: {
                            title: "Úprava klienta",
                        },
                    },
                    {
                        name: "clients.detail",
                        path: "detail/:id",
                        component: ClientShow,
                        props: true,
                        meta: {
                            title: "Detail klienta",
                        },
                    },
                ],
            },
            {
                name: "users",
                path: "/users/",
                component: Users,
                redirect: { name: "users.list", params: { id: 0 } },
                meta: {
                    title: "Používatelia",
                    permission: 1,
                },
                children: [
                    {
                        name: "users.list",
                        path: "list/:id",
                        component: UsersList,
                        meta: {
                            title: "Používatelia",
                        },
                    },
                    {
                        name: "users.create",
                        path: "create",
                        component: UserForm,
                        meta: {
                            title: "Pridanie nového používateľa",
                        },
                    },
                    {
                        name: "users.edit",
                        path: "edit/:id",
                        component: UserForm,
                        props: true,
                        meta: {
                            title: "Úprava používateľa",
                        },
                    },
                    {
                        name: "users.detail",
                        path: "detail/:id",
                        component: UserShow,
                        props: true,
                        meta: {
                            title: "Detail používateľa",
                        },
                    },
                ],
            },
            {
                name: "profile",
                path: "/profile/",
                component: Users,
                redirect: { name: "profile.edit" },
                meta: {
                    title: "Nastavenia",
                },
                children: [
                    {
                        name: "profile.edit",
                        path: "edit",
                        component: UserForm,
                        meta: {
                            title: "Úprava profilu",
                        },
                    },
                ],
            },
            {
                name: "notFound",
                path: "/:notFound(.*)",
                component: NotFound,
                meta: {
                    title: "Stránka nenájdená",
                },
            },
        ],
    },
];
