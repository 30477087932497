<template>
    <form ref="form" @submit.prevent="handleSubmit()" v-if="!loading">
        <row>
            <column :type="6">
                <card title="Základné udaje">
                    <div class="form-wrap">
                        <div class="form-item form-item--full">
                            <label for="name">Názov klienta</label>
                            <input
                                type="text"
                                id="name"
                                class="input"
                                :class=" errorsMutable.title ? 'input hasError' : 'input'"
                                v-model="formTitleCapitalize"
                                required
                            />
                            <div v-if="errorsMutable.title">
                                <span class="error">{{errorsMutable.title[0]}}</span>
                            </div>
                        </div>
                        <div class="form-item form-item--full">
                            <label for="color">Farba klienta</label>
                            <input
                                type="color"
                                id="color"
                                class="input"
                                v-model="form.color"
                            />
                        </div>
                    </div>
                </card>
            </column>
            <column :type="6">
                <card title="Doplňujúce udaje">
                    <div class="form-wrap">
                        <div class="form-item form-item--full">
                            <label for="description">Popis klienta</label>
                            <textarea
                                type="text"
                                id="description"
                                class="input textarea"
                                rows="6"
                                v-model="form.description"
                            ></textarea>
                        </div>
                    </div>

                    <div class="form-wrap">
                        <div class="form-item form-item--full">
                            <label for="description">Hodinová sadzba</label>
                            <input
                                type="number"
                                id="hourly_rate"
                                :class=" errorsMutable.hourly_rate ? 'input hasError' : 'input'"
                                v-model="form.hourly_rate"
                            />
                        </div>
                    </div>
                </card>
            </column>
            <column>
                <card title="Zákazníci">
                    <div class="form-wrap">
                        <div class="form-item form-item--full">
                            <input
                                type="text"
                                class="input"
                                placeholder="Vyhľadávanie zákazníka"
                                autocomplete="off"
                                v-model="filterCustomersQuery"
                            />
                        </div>
                        <div
                            class="form-item form-item--full"
                            v-for="customer in resultFilterCustomers"
                            :key="customer.id"
                        >
                            <label class="checkbox">
                                <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    v-model="form.customers"
                                    :value="customer.id"
                                />
                                <span class="checkbox__in">
                                    <span class="checkbox__tick"></span>
                                    <span class="checkbox__text">{{
                                        customer.name
                                    }}</span>
                                </span>
                            </label>
                        </div>
                    </div>
                </card>
            </column>
            <column>
                <card>
                    <div class="form-wrap">
                        <div class="form-buttons">
                            <button class="btn btn_purple" :disabled="sending">
                                <span class="loader" v-if="sending"></span>
                                <span v-else>Uložiť</span>
                            </button>
                        </div>
                    </div>
                </card>
            </column>
        </row>
    </form>
    <loader v-else></loader>
</template>

<script>
import { formTitleCapitalize } from "../../mixins/index.js";

export default {
    mixins: [formTitleCapitalize],
    data() {
        return {
            loading: true,
            sending: false,
            errorsMutable: {},
            id: null,
            customers: [],
            form: {
                title: "",
                color: "#bada55",
                customers: [],
                description: "",
                hourly_rate: 0.00,
            },
            filterCustomersQuery: "",
        };
    },
    computed: {
        resultFilterCustomers() {
            if (this.filterCustomersQuery) {
                return this.customers.filter((item) => {
                    return this.filterCustomersQuery
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.name.toLowerCase().includes(v));
                });
            } else {
                return this.customers;
            }
        },
    },
    methods: {
        async fetchCustomers() {
            try {
                const response = await this.$axios.get(
                    "/api/users/list/customers"
                );
                const responseData = response.data;

                this.customers = responseData.data;
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
        async fetchClient() {
            try {
                const response = await this.$axios.get(
                    `/api/clients/${this.id}`
                );
                const responseData = response.data;

                this.form.title = responseData.data.title;
                this.form.color = responseData.data.color;
                this.form.customers = [];
                responseData.data.customers.forEach((customer) => {
                    this.form.customers.push(customer.id);
                });
                this.form.description = responseData.data.description;
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
        handleSubmit() {
            this.errorsMutable = {};
            if (this.id) {
                this.submitEdit();
            } else {
                this.submitCreate();
            }
        },
        submitCreate() {
            this.sending = true;

            let formData = new FormData();

            formData.append("title", this.form.title);
            formData.append("color", this.form.color);
            formData.append("users", this.form.customers);
            formData.append("description", this.form.description);
            formData.append("hourly_rate", this.form.hourly_rate);

            this.$axios
                .post("/api/clients", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    this.$toast.success("Klient bol úspešne vytvorený");
                    this.resetForm();
                })
                .catch((error) => {
                    this.errorsMutable = error.response.data.errors;

                    this.$toast.error("Klienta sa nepodarilo vytvoriť");
                })
                .then(() => {
                    this.sending = false;
                });
        },
        submitEdit() {
            this.sending = true;

            let formData = new FormData();

            formData.append("_method", "PATCH");
            formData.append("title", this.form.title);
            formData.append("color", this.form.color);
            formData.append("users", this.form.customers);
            formData.append("description", this.form.description);
            formData.append("hourly_rate", this.form.hourly_rate);

            this.$axios
                .post(`/api/clients/${this.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    this.$toast.success("Klient bol úspešne upravený");
                    this.fetchClient();
                })
                .catch((error) => {
                    this.errorsMutable = error.response.data.errors;
                    this.$toast.error("Klienta sa nepodarilo upraviť");
                })
                .then(() => {
                    this.sending = false;
                });
        },
        resetForm() {
            this.form.title = "";
            this.form.color = "#bada55";
            this.form.customers = [];
            this.form.description = "";
        },
    },
    created() {
        if (this.$route.name === "clients.edit") {
            this.id = this.$route.params.id;
            this.fetchClient();
        }
        this.fetchCustomers();
    },
};
</script>

