<template>
    <container>
        <page-head></page-head>
        <div class="filter" v-if="$route.name === 'clients.list'">
            <router-link :to="'/clients/list/' + 0" class="filter__link"
                >Všetci</router-link
            >
            <router-link :to="'/clients/list/' + 1" class="filter__link"
                >Priradení</router-link
            >
            <router-link :to="'/clients/list/' + 2" class="filter__link"
                >Nepriradení</router-link
            >
            <router-link :to="'/clients/list/' + 3" class="filter__link"
                >Vymazaní</router-link
            >
        </div>
        <router-view v-slot="{ Component }">
            <transition name="child" mode="out-in">
                <component :is="Component" :key="$route.path" />
            </transition>
        </router-view>
        <div class="floated-buttons">
            <router-link
                :to="{ name: 'clients.create' }"
                class="btn btn_purple"
                v-if="
                    $route.name !== 'clients.detail' &&
                    $route.name !== 'clients.create' &&
                    $route.name !== 'clients.edit'
                "
            >
                Nový klient
            </router-link>
            <router-link
                :to="{ name: 'clients.edit', params: { id: $route.params.id } }"
                class="btn btn_purple"
                v-if="$route.name === 'clients.detail'"
            >
                Upraviť klienta
            </router-link>
        </div>
    </container>
</template>
