<template>
    <card v-if="!loading">
        <table-clients :clients="clients"></table-clients>
    </card>
    <loader v-else></loader>
</template>

<script>
import TableClients from "../../components/Table/TableClients.vue";
import eventBus from "../../eventBus";
export default {
    components: { TableClients },
    data() {
        return {
            id: null,
            loading: true,
            clients: [],
        };
    },
    methods: {
        async fetchClients() {
            let apiTarget;

            switch (this.id) {
                case "1":
                    apiTarget = "clients/list/assigned";
                    break;
                case "2":
                    apiTarget = "clients/list/unassigned";
                    break;
                case "3":
                    apiTarget = "clients/list/deleted";
                    break;
                default:
                    apiTarget = "clients";
            }

            try {
                const response = await this.$axios.get(`/api/${apiTarget}`);
                const responseData = response.data;

                this.clients = responseData.data;
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
    },
    mounted() {
        eventBus.$on('clients', () => { this.fetchClients() });
    },
    beforeUnmount() {
        eventBus.$off('clients');
    },
    created() {
        this.id = this.$route.params.id;
        this.fetchClients();
    },
};
</script>
