<template>
    <div class="page__col" :class="customClass">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ["type"],
    computed: {
        customClass() {
            if (this.type) {
                return "page__col-" + this.type;
            }
        },
    },
};
</script>
