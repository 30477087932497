<template>
    <div >
        <TaskComment
            :comment="comment"
            v-if="comments.length > 0 && (!loading && !loadingUsers)"
            v-for="comment in comments" :loading="loading && loadingUsers"
            :users="users"
        ></TaskComment>

        <div v-if="comments.length === 0" class="card">
            <h2>Žiadne komentáre</h2>
        </div>
        <div v-if="!loading && toReply == null && toEdit == null">
            <div>
                <textarea name="comment" id="comment" v-model="editText">{{editText}}</textarea>
            </div>
            <div>
                <button @click="postComment()" class="btn btn_purple" :disabled="loading" >Odoslať</button>
            </div>
        </div>
        <loader v-if="loading"></loader>
        <user-name-autocomplete :users="filteredUsers" ></user-name-autocomplete>
    </div>


</template>

<script>
import eventBus from "../../eventBus";
import TaskComment from "./TaskComment";
import UserNameAutocomplete from "../Users/UserNameAutocomplete";

export default {
    name: "TaskComments",
    components: {UserNameAutocomplete, TaskComment},
    props: {
        taskId: {
            type: Number
        }
    },
    watch: {
        editText: {
            handler: function(val, oldVal) {
                console.log(val)
                if ( val[val.length-1] === '@') {
                    this.resetFilter();
                    eventBus.$emit('atPressed');
                } else {
                    this.filterString = val.split('@').pop();
                    this.filterUsers();
                }
            }
        }
    },
    data() {
        return {
            loading: false,
            loadingUsers: true,
            comments: [],
            toDelete: null,
            toReply: null,
            toEdit: null,
            editText: '',
            users: [],
            filteredUsers: [],
            filterString: '',
        }
    },
    mounted() {
        this.fetchUsers();
        this.fetchComments();
        eventBus.$on('comments', () => { this.fetchComments() });

        eventBus.$on('editComment' , (data)=> {
            this.toEdit = data.id;
            this.editText = data.comment;
            this.toReply = null;
        });
        eventBus.$on('replyToComment' , (data)=> {
            this.toReply = data.id
            this.toEdit = null;
            this.editText = '';
        });
        eventBus.$on('deleteComment' , (data)=> {
            this.toDelete = data.id;
            this.deleteComment(this.toDelete)
        });
        eventBus.$on('postComment' , (data)=> {
            this.toEdit = data.edit_id;
            this.toReply = data.reply_id
            this.postComment()
        });
        eventBus.$on('commentChanged', (data) => {
            this.editText = data;
        });
        eventBus.$on('cancelComment', ()=>{
            this.toReply = null;
            this.toEdit = null;
            this.editText = '';
        });
        eventBus.$on('userSelected', (data)=>{

            this.editText = this.editText.replace('@'+this.filterString, '@'+data);
            this.filterString = '';
        });
    },
    beforeUnmount() {
        eventBus.$off('cancelComment');
        eventBus.$off('userSelected');
        eventBus.$off('commentChanged');
        eventBus.$off('postComment');
        eventBus.$off('deleteComment');
        eventBus.$off('editComment');
        eventBus.$off('replyToComment');
        eventBus.$off('comments');
    },
    methods: {
        async fetchComments() {
            try {
                this.loading = true;
                const response = await this.$axios.get(`/api/comments/${this.taskId}/list`);
                this.comments = response.data;
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
        async fetchUsers() {
            try {
                this.loadingUsers = true;
                const response = await this.$axios.get(`/api/comment-user/list`);
                this.users = response.data;
                this.filteredUsers = response.data;
                this.loadingUsers = false;
            } catch (error) {
                console.log("error");
            }

        },
        postComment() {
            let formData = new FormData;
            let url = `/api/comments/${this.taskId}/create`;


            if(this.toReply) {
                formData.set('parent_comment_id', this.toReply);
            } else if(this.toEdit) {
                url = `/api/comments/${this.taskId}/edit/${this.toEdit}`;
                formData.set('_method', 'PUT');
            }
            let comment = document.querySelector('#comment').value
            formData.set('comment',comment);
            this.loading = true;
            this.$axios.post(url, formData).then(
                (data) => {
                    this.toReply = null;
                    this.toEdit = null;
                    this.editText = '';
                    // document.querySelector('#comment').value = '';
                    this.fetchComments();
                }
            )
        },
        deleteComment(id) {
            if ( confirm('Naozaj chcete odstrániť komentár?') == true) {
                let url = `/api/comments/${this.taskId}/edit/${id}/delete`;
                this.loading = true;
                this.$axios.delete(url).then(
                    (data) => {
                        this.toReply = null;
                        this.toEdit = null;
                        this.editText = '';
                        // document.querySelector('#comment').value = '';
                        this.fetchComments();
                    }
                )
            }
        },
        filterUsers() {
            this.filteredUsers = this.users.filter((user) => {

                return user.name.toLowerCase().includes(this.filterString.toLowerCase());
            });

        },
        resetFilter(){
            this.filteredUsers = this.users;
        }
    }
}
</script>

<style scoped>
textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}
</style>
