const state = () => ({
    sidebarSmall: false,
    sidebarActive: false,
});
const getters = {
    getSidebarSmall(state) {
        return state.sidebarSmall;
    },
    getSidebarActive(state) {
        return state.sidebarActive;
    },
};
const actions = {
    setSidebarSmall({ commit }) {
        commit("setSidebarSmall");
    },
    setSidebarActive({ commit }, payload) {
        commit("setSidebarActive", payload);
    },
};
const mutations = {
    setSidebarSmall(state) {
        state.sidebarSmall = !state.sidebarSmall;
    },
    setSidebarActive(state, payload) {
        if (payload) {
            state.sidebarActive = payload.value;
        } else {
            state.sidebarActive = !state.sidebarActive;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
