<template>
    <router-view v-slot="{ Component }">
        <transition name="page" mode="out-in">
            <component :is="Component" :key="$route.key" />
        </transition>
    </router-view>
</template>

<script>
import eventBus from './eventBus'
export default {
    data() {
        return {
            loading: false,
            id: null
        };
    },
    mounted() {
        this.id = this.$store.state.auth.user.id;
        window.formatTime = this.formatTime;
        eventBus.$on('notifications', (event) => {
            console.log('reading notifications...')
            this.loadNotifications()
        });
        this.loadNotifications();
        window.setInterval(() => {
            if (this.id) {
                this.checkDBFreshness();
            }
        }, 30000);


    },
    beforeUnmount() {
        eventBus.$off('notifications');
    },

    methods: {
        loadNotifications() {
              if(this.id) {
                  console.log()
                  this.loading = true;
                  this.$axios.get(`/api/notifications`).then((data) => {
                      this.notifications = data.data;

                      if (this.notifications.unread_count > 0) {
                          eventBus.$emit('unread_notifications', this.notifications.unread_count);
                      }
                      this.loading = false;
                  });
                  // const responseData = response.data;
                  // this.notifications = responseData.data;

              }

        },
        checkDBFreshness() {
            if(this.id) {
                this.$axios
                    .get(`/api/data-freshness`)
                    .then((data) => {
                        let rows = data.data;
                        console.log(rows)
                        for (let i = 0; i < rows.length; i++) {
                            eventBus.$emit(rows[i].class, null);
                        }
                    })
                    .catch((error) => {
                        // this.checkDBFreshness();
                    });
            }

        },
        formatTime(time) {
            let hours = parseInt(time/3600);
            let hoursRemainder = time % 3600;
            let minutes = parseInt(hoursRemainder / 60);
            let seconds = hoursRemainder % 60;
            return ('0' + hours).slice(-2) + ':'+ ('0' + minutes).slice(-2) + ':'+ ('0' + seconds).slice(-2);
        }
    },
};
</script>
<style lang="scss">
// Base page animation
.page-enter-active,
.page-leave-active {
    transition: all 0.3s;
}
.page-enter-from {
    opacity: 0;
}
.page-leave-to {
    opacity: 0;
}
.child-enter-active,
.child-leave-active {
    transition: all 0.3s;
}
.child-enter-from {
    opacity: 0;
    transform: translateY(-10px);
}
.child-leave-to {
    opacity: 0;
    transform: translateY(10px);
}

// Floated buttons
.floated-buttons {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 999;

    .btn {
        margin-left: 10px;
    }
}

// Progress bar (nprogress)
#nprogress {
    position: relative;
    pointer-events: none;
    z-index: 1031;
}

#nprogress .bar {
    background: #6c5dd3;

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 5px;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    opacity: 1;

    transform: rotate(3deg) translate(0px, -4px);
}

// Toast notification
.c-toast-container {
    font-size: 16px;
    padding: 1rem;
}
</style>
