<template>
    <button
        size="medium"
        class="badge"
        :class="tagType(typeId)"
        @click="handleShowList(typeId)"
    >
        {{ title }}
    </button>
</template>

<script>
export default {
    props: ["typeId", "title"],
    methods: {
        tagType(id) {
            if (id === 1) {
                return "badge--red";
            } else if (id === 2) {
                return "badge--green";
            } else if (id === 3) {
                return "badge--blue";
            } else {
                return "color-purple bg-purple-opacity";
            }
        },
        handleShowList(id) {
            console.log(id);
            this.$router.push({ name: "users.list", params: { id: id } });
        },
    },
};
</script>

<style lang="scss" scoped>
.badge {
    display: inline-block;
    margin: 4px;
    padding: 0 16px;
    border-radius: 16px;
    font-size: 12px;
    line-height: 32px;
    font-weight: 500;
    cursor: pointer;

    &--green {
        color: #4fbf67;
        background: rgba(#4fbf67, 0.15);
    }
    &--red {
        color: #ff6628;
        background: rgba(#ff6628, 0.15);
    }
    &--blue {
        color: #4fc3f7;
        background: rgba(#4fc3f7, 0.15);
    }
}
</style>
