<template>
    <container>
        <page-head></page-head>
        <router-view v-slot="{ Component }">
            <transition name="child" mode="out-in">
                <component :is="Component" :key="$route.path" />
            </transition>
        </router-view>
    </container>
</template>

<script>
export default {
    name: "EmailsDefaultComponent"
}
</script>

<style scoped>

</style>
