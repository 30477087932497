<template>
    <custom-table :data="tasks">
        <template v-slot:head>
            <div
                class="table__cell"
                :class="'table__cell--' + column.class"
                v-for="(column, index) in columns"
                :key="index"
            >
                {{ column.name }}
            </div>
        </template>
        <template v-slot:body>
            <div
                class="table__row"
                v-for="(task, index) in tasks"
                :key="task.id"
                :class="{ active: activeTableRow === index }"
            >
                <div class="table__cell table__cell--status">
                    <label class="checkbox">
                        <input
                            class="checkbox__input"
                            type="checkbox"
                            :checked="task.status.id == 2 ? true : false"
                            @click="
                                handleStatus(task.id, task.status.id, index)
                            "
                            :disabled="
                                this.$route.name === 'tasks.list' &&
                                this.$route.params.id === '4'
                            "
                        />
                        <span class="checkbox__in">
                            <span class="checkbox__tick"></span>
                        </span>
                    </label>
                </div>
                <div class="table__cell table__cell--title">
                    <router-link
                        :to="{
                            name: 'tasks.detail',
                            params: { id: task.id },
                        }"
                        class="table__title"
                    >
                        {{ task.title }}
                        <small class="table__description">{{
                            task.description
                        }}</small>
                    </router-link>
                </div>
                <div class="table__cell table__cell--priority">
                    <priority
                        :value="task.priority.value"
                        :readonly="true"
                    ></priority>
                </div>
                <div class="table__cell table__cell--project">
                    <color-box
                        v-if="task.project"
                        :color="task.project.color"
                        :title="task.project.title"
                    ></color-box>
                </div>
                <div class="table__cell table__cell--customers">
                    <tooltip
                        v-for="developer in task.developers"
                        :key="developer.id"
                        :title="developer.name"
                    >
                        <template v-slot:head>
                            <router-link
                                :to="{
                                    name: 'users.detail',
                                    params: { id: developer.id },
                                }"
                            >
                                <avatar
                                    :name="developer.name"
                                    type="small space"
                                ></avatar>
                            </router-link>
                        </template>
                        <template v-slot:body>
                            <p>{{ developer.name }}</p>
                        </template>
                    </tooltip>
                </div>
                <div class="table__cell table__cell--date">
                    <span class="table__text">
                        {{ task.deadline_at }}
                    </span>
                </div>
                <div class="table__cell table__cell--operations">
                    <dropdown>
                        <template v-slot:methods>
                            <router-link
                                :to="{
                                    name: 'tasks.edit',
                                    params: { id: task.id },
                                }"
                                class="dropdown__link"
                            >
                                Upraviť
                            </router-link>
                            <router-link
                                :to="{
                                    name: 'tasks.detail',
                                    params: { id: task.id },
                                }"
                                class="dropdown__link"
                            >
                                Zobraziť
                            </router-link>
                            <button
                                class="dropdown__link"
                                @click="handleDelete(task.id, index)"
                                v-if="id !== '5'"
                            >
                                Vymazať
                            </button>
                            <button
                                class="dropdown__link"
                                @click="handleRestore(task.id, index)"
                                v-if="id === '5'"
                            >
                                Obnoviť
                            </button>
                        </template>
                    </dropdown>
                </div>
            </div>
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "./index.vue";
import RoleTag from "../RoleTag/index.vue";

export default {
    props: ["tasks"],
    components: { CustomTable, RoleTag },
    data() {
        return {
            id: null,
            columns: [
                { name: "Stav", class: "status" },
                { name: "Názov", class: "name" },
                { name: "Priorita", class: "priority" },
                { name: "Projekt", class: "project" },
                { name: "Developeri", class: "customers" },
                { name: "Deadline", class: "date" },
                { name: "Operácie", class: "operations" },
            ],
            activeTableRow: null,
        };
    },
    methods: {
        handleStatus(id, status_id, index) {
            this.activeTableRow = index;

            let formData = new FormData();

            formData.append("_method", "PATCH");
            formData.append("status_id", status_id == 1 ? 2 : 1);

            this.$axios
                .post(`/api/tasks/status/${id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    this.$toast.success("Stav úlohy bol úspešne zmenený");
                    if (
                        this.$route.name === "tasks.list" &&
                        this.$route.params.id !== "0"
                    ) {
                        this.tasks.splice(index, 1);
                    }
                })
                .catch((error) => {
                    this.$toast.error("Stav úlohy sa nepodarilo zmeneniť");
                })
                .then(() => {
                    this.activeTableRow = null;
                });
        },
        handleDelete(id, index) {
            this.activeTableRow = index;
            this.$axios
                .delete(`/api/tasks/${id}`)
                .then((response) => {
                    this.$toast.success("Klient bol úspešne vymazaný");
                    this.tasks.splice(index, 1);
                })
                .catch((error) => {
                    this.$toast.error("Klienta sa nepodarilo vymazať");
                })
                .then(() => {
                    this.activeTableRow = null;
                });
        },
        handleRestore(id, index) {
            this.activeTableRow = index;

            this.$axios
                .post(`/api/tasks/restore/${id}`)
                .then((response) => {
                    this.$toast.success("Klient bol úspešne obnovený");
                    this.tasks.splice(index, 1);
                })
                .catch((error) => {
                    this.$toast.error("Klienta sa nepodarilo obnoviť");
                })
                .then(() => {
                    this.activeTableRow = null;
                });
        },
    },
    created() {
        this.id = this.$route.params.id;
    },
};
</script>
