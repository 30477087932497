<template>
    <div v-if="!loading">
        <div class="time_tracker" v-if="activeTracker">
            <div class="tracker-body">
                    <span class="tracker-icon">
                          <font-awesome-icon
                              :icon="['fa','clock']"
                          ></font-awesome-icon>
                    </span>
                <span class="tracker-title">
                        {{tracker.description}}
                    </span>
                <span class="tracker-time">
                        {{ formatTime(time) }}
                    </span>
                <span class="tracker-actions">
                         <font-awesome-icon
                             :icon="['fa','stop-circle']"
                             class="cursor-pointer"
                             @click="stopTimer()"
                         ></font-awesome-icon>
                    </span>
            </div>
        </div>
    </div>
    <loader v-else> </loader>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faClock, faStopCircle } from '@fortawesome/free-solid-svg-icons'
library.add( faClock)
library.add( faStopCircle)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Loader from '../Loader/index'
import eventBus from "../../eventBus";
export default {
    name: "TimeTracker",
    components: {Loader, FontAwesomeIcon},
    data(){
        return {
            loading: true,
            tracker: [],
            activeTracker: false,
            time: 0
        }
    },
    mounted() {
        this.loadTimeTracker();
        setInterval( () => {
            this.computeTime();
        }, 1000);
        eventBus.$on('newTimeTracker', (data) => {
            this.tracker = data;
            this.activeTracker = true;
        })
    },
    unmounted() {
        eventBus.$off('newTimeTracker');
    },
    methods: {
        loadTimeTracker() {
            this.$axios.get('/api/time-tracker/current').then(
                (data) => {
                    console.log(data.data);
                    let tracker = data.data;
                    if(tracker.length !== 0) {
                        this.tracker = tracker;
                        this.activeTracker = true;
                    }
                    this.loading = false
                }
            )
        },
        computeTime() {
            if (this.activeTracker) {
                this.time = parseInt(Date.now()/1000 - this.tracker.started_s );
            }

        },
        formatTime(time) {
            return window.formatTime(time)
        },
        stopTimer() {
            let formData = new FormData;
            formData.set('_method', 'PUT');
            this.$axios.post(`/api/time-tracker/${this.tracker.id}/complete`, formData).then(
                (data) => {
                    this.tracker = [];
                    this.activeTracker = false;
                    this.$toast.success("Úspešne ste zastavili trackovanie");
                }
            )
        }
    }
}
</script>

<style scoped>
.time_tracker {
    position: fixed;
    top: 0px;
    left: 40%;
    padding-top: 10px;
    padding-left: 1em;
    padding-right: 1em;
    border-left: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.tracker-body {
    padding: 5px 5px 5px 5px;
}
.tracker-title, .tracker-time, .tracker-actions {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
}
</style>
