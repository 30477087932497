<template>
    <transition name="child" mode="out-in">
        <row v-if="!loading.tasks && !loading.projects">
            <column>
                <card title="Moje dnešné úlohy">
                    <table-tasks :tasks="tasks"></table-tasks>
                    <template v-slot:footer v-if="count.tasks > count.default">
                        <router-link
                            :to="{ name: 'tasks' }"
                            class="btn btn_black"
                            >Zobraziť všetky</router-link
                        >
                    </template>
                </card>
            </column>
            <column>
                <card title="Mne priradené projekty">
                    <table-projects :projects="projects"></table-projects>
                    <template
                        v-slot:footer
                        v-if="count.projects > count.default"
                    >
                        <router-link
                            :to="{ name: 'projects' }"
                            class="btn btn_black"
                            >Zobraziť všetky</router-link
                        >
                    </template>
                </card>
            </column>
        </row>
        <loader v-else></loader>
    </transition>
</template>

<script>
import TableTasks from "../../components/Table/TableTasks.vue";
import TableProjects from "../../components/Table/TableProjects.vue";
import eventBus from "../../eventBus";

export default {
    components: { TableTasks, TableProjects },
    data() {
        return {
            user: null,
            loading: {
                tasks: true,
                projects: true,
            },
            tasks: [],
            projects: [],
            count: {
                default: 5,
                tasks: 0,
                projects: 0,
            },
        };
    },
    methods: {
        async fetchTasks() {
            try {
                const response = await this.$axios.get(
                    `/api/tasks/list/today/${this.user.id}`
                );
                const responseData = response.data;

                this.tasks = responseData.data.slice(0, this.count.default);
                this.count.tasks = responseData.data.length;

                this.loading.tasks = false;
            } catch (error) {
                console.log("error");
            }
        },
        async fetchProjects() {
            try {
                const response = await this.$axios.get(
                    `/api/projects/list/assigned/${this.user.id}`
                );
                const responseData = response.data;

                this.projects = responseData.data.slice(0, this.count.default);
                this.count.projects = responseData.data.length;

                this.loading.projects = false;
            } catch (error) {
                console.log("error");
            }
        },
    },
    created() {
        this.user = this.$store.state.auth.user;
        this.fetchTasks();
        this.fetchProjects();
    },
    mounted() {
        eventBus.$on('tasks', (event)=> {
            this.fetchTasks();
        });
        eventBus.$on('projects', (event)=> {
            this.fetchProjects();
        });
    },
    beforeUnmount() {
        eventBus.$off('tasks');
        eventBus.$off('projects');
    },
};
</script>
