<template>
    <div class="star-rating">
        <span
            v-for="(rating, index) in ratings"
            :key="index"
            :class="{
                'is-selected': value >= rating && value != null,
            }"
            class="star-rating__star"
            >★</span
        >
    </div>
</template>

<script>
export default {
    props: {
        value: null,
    },
    data() {
        return {
            ratings: [1, 2, 3, 4, 5],
        };
    },
};
</script>

<style lang="scss" scoped>
.star-rating {
    display: flex;
    align-items: center;
    margin: 0 -2px;

    &__star {
        display: inline-block;
        padding: 2px;
        vertical-align: middle;
        line-height: 1;
        font-size: 1em;
        color: #ededed;
        margin-bottom: 0;
        transition: color 0.2s ease-out;

        &.is-selected {
            color: #e91e63;
        }
    }
}
</style>
