<template>
    <div
        class="sidebar"
        :class="{ small: isSidebarSmall, active: isSidebarActive }"
    >
        <div class="sidebar__head">
            <router-link :to="{ name: 'home' }" class="sidebar__logo">
                <img
                    class="sidebar__pic sidebar__pic_light"
                    src="/img/logo.svg"
                    alt=""
                />
            </router-link>
            <button class="sidebar__toggle" @click="toggleIsSmall()">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                >
                    <path d="M22 12H3" stroke="#11142d"></path>
                    <g stroke="#808191">
                        <path d="M22 4H13"></path>
                        <path opacity=".301" d="M22 20H13"></path>
                    </g>
                    <path d="M7 7l-5 5 5 5" stroke="#11142d"></path>
                </svg>
            </button>
            <button class="sidebar__close" @click="toggleIsActive()">
                <svg class="icon icon-close">
                    <use xlink:href="/img/sprite.svg#icon-close"></use>
                </svg>
            </button>
        </div>
        <div class="sidebar__body">
            <nav class="sidebar__nav">
                <navigation></navigation>
            </nav>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

import Navigation from "./Navigation.vue";

export default {
    components: { Navigation },
    computed: {
        isSidebarSmall() {
            return this.$store.state.base.sidebarSmall;
        },
        isSidebarActive() {
            return this.$store.state.base.sidebarActive;
        },
    },
    methods: {
        ...mapActions({
            toggleIsSmall: "base/setSidebarSmall",
            toggleIsActive: "base/setSidebarActive",
        }),
    },
};
</script>
<style scoped>

</style>
