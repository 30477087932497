<template>
    <container>
        <page-head></page-head>

        <dashboard-admin v-if="permission === 1"></dashboard-admin>
        <dashboard-developer v-if="permission === 2"></dashboard-developer>
        <dashboard-customer v-if="permission === 3"></dashboard-customer>
    </container>
</template>

<script>
import DashboardAdmin from "./DashboardAdmin.vue";
import DashboardDeveloper from "./DashboardDeveloper.vue";
import DashboardCustomer from "./DashboardCustomer.vue";

export default {
    components: { DashboardAdmin, DashboardDeveloper, DashboardCustomer },
    data() {
        return {
            permission: this.$store.state.auth.permission,
        };
    },
};
</script>
