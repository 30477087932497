<template>
    <container>
        <page-head></page-head>
        <div class="filter">
            <span style="cursor: pointer;" :class="displayAll ?'filter__link active' : 'filter__link'" @click="setDisplayOptions(0)"> Všetky</span>
            <span style="cursor: pointer;" :class="displayRead ?'filter__link active' : 'filter__link'" @click="setDisplayOptions(1)"> Prečítané</span>
            <span style="cursor: pointer;" :class="displayUnRead ?'filter__link active' : 'filter__link'" @click="setDisplayOptions(2)">Neprečítane</span>
        </div>
        <row>
            <column>
                <card>
                    <div class="notifications__list">
                        <div class="notifications__item" v-for="notification in notifications.unread " v-if="displayAll || displayUnRead">

                            <div class="notifications__icon bg-purple-gradient">
                                <img src="img/pig.svg" alt="" />
                            </div>
                            <div class="notifications__details">
                                <div class="notifications__head">
                                    <div class="notifications__title">
                                        <router-link :to="notification.link" @click="setRead(notification.id)">{{ notification.title }}</router-link>
                                    </div>
                                    <div class="notifications__time">
                                        {{ formatDate(notification.date) }}
                                    </div>
                                </div>
                                <div class="notifications__body">
                                    <div class="notifications__text">
                                        {{ notification.description }}
                                    </div>
                                    <div
                                        class="notifications__status bg-blue-light"
                                    ></div>
                                </div>
                            </div>
                        </div>

                        <div class="notifications__item disabled" v-for="notification in notifications.read " v-if="displayAll || displayRead">
                            <div class="notifications__icon bg-purple-gradient">
                                    <img src="img/pig.svg" alt="" />
                                </div>
                            <div class="notifications__details">
                                    <div class="notifications__head">
                                        <div class="notifications__title">
                                            <router-link :to="notification.link">{{ notification.title }}</router-link>
                                        </div>
                                        <div class="notifications__time">
                                            {{ formatDate(notification.date) }}
                                        </div>
                                    </div>
                                    <div class="notifications__body">
                                        <div class="notifications__text">
                                            {{ notification.description }}
                                        </div>
                                        <div
                                            class="notifications__status bg-blue-light"
                                        ></div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </card>
            </column>
<!--            <column>-->
<!--                <div class="text-center">-->
<!--                    <button-->
<!--                        class="btn btn_purple"-->
<!--                        :disabled="loading"-->
<!--                        @click="loadNotifications"-->
<!--                    >-->
<!--                        <span class="loader" v-if="loading"></span>-->
<!--                        <span v-else>Načítať ďalšie</span>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </column>-->
        </row>
    </container>
</template>

<script>
import eventBus from "../eventBus";

export default {
    data() {
        return {
            loading: false,
            notifications: [],
            displayAll: true,
            displayRead: false,
            displayUnRead: false,
        };
    },
    mounted() {
        this.loadNotifications();
        eventBus.$on('tasks', () => { this.fetchTasks() });
    },
    beforeUnmount() {
        eventBus.$off('tasks');
    },

    methods: {
        formatDate(dateString){
            let options = {  year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            let today  = new Date(dateString);
            return today.toLocaleDateString("sk-SK", options);
        },
        async loadNotifications() {
            this.loading = true;
            const response = await this.$axios.get(`/api/notifications`);
            // const responseData = response.data;
            // this.notifications = responseData.data;
            this.notifications = response.data;
            if (this.notifications.unread_count > 0) {
                eventBus.$emit('unread_notifications', this.notifications.unread_count);
            }
            this.loading = false;
        },
        setRead(id) {
            this.$axios.post(`/api/notifications/${id}/read`).then((data) => { this.loadNotifications();} );
            eventBus.$emit('unread_notifications', this.notifications.unread_count-1);
            // const responseData = response.data;
            // this.notifications = responseData.data;

        },
        setDisplayOptions(num) {
            if(num === 0) {
                this.displayAll = true;
                this.displayRead = false;
                this.displayUnRead = false;
            }
            if(num === 1) {
                this.displayAll = false;
                this.displayRead = true;
                this.displayUnRead = false;
            }
            if(num === 2) {
                this.displayAll = false;
                this.displayRead = false;
                this.displayUnRead = true;
            }
        }
    },
};
</script>
