<template>
    <div>
        <custom-table :data="trackers">
            <template v-slot:head>
                <div
                    class="table__cell"
                    :class="'table__cell--' + column.class"
                    v-for="(column, index) in columns"
                    :key="index"
                >
                    {{ column.name }}
                </div>
            </template>
            <template v-slot:body>
                <div
                    class="table__row"
                    v-for="(tracker, index) in trackers"
                    :key="tracker.id"
                    :class="{ active: activeTableRow === index }"
                >
                    <div class="table__cell table__cell--user">
                        {{ tracker.user }}
                    </div>

                    <div class="table__cell table__cell--running">
                    <span class="table__text">

                        <font-awesome-icon
                            v-if="tracker.running"
                            :icon="['fa','cog', 'spin']"
                            class="cursor-pointer"
                        ></font-awesome-icon>
                        <font-awesome-icon
                            v-else
                            :icon="['fa','check']"
                            class="cursor-pointer"
                        ></font-awesome-icon>
                    </span>
                    </div>
                    <div class="table__cell table__cell--started">
                    <span class="table__text">
                        {{ tracker.started }}
                    </span>
                    </div>
                    <div class="table__cell table__cell--elapsed_time">
                    <span class="table__text">
                        {{ tracker.elapsed_time }}
                    </span>
                    </div>
                </div>
            </template>
        </custom-table>
        <div>
            Celkom: {{calculateAndFormatTimeSpent()}}
        </div>
    </div>
</template>

<script>
import CustomTable from "./index.vue";
import RoleTag from "../RoleTag/index.vue";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCog, faCheck } from '@fortawesome/free-solid-svg-icons'
library.add( faCog)
library.add( faCheck)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default {
    props: ["trackers"],
    components: { CustomTable, RoleTag, FontAwesomeIcon },
    data() {
        return {
            id: null,
            columns: [
                { name: "Meno", class: "user" },
                { name: "Stav", class: "running" },
                { name: "Začal riešit", class: "started" },
                { name: "Strávený čas", class: "elapsed_time" },
            ],
            activeTableRow: null,
            timeSpent: 0,
        };
    },
    methods: {
        calculateAndFormatTimeSpent() {
            let time = 0;
            for (let i = 0; i < this.trackers.length; i++) {
                time += this.trackers[i].elapsed_time_s;
            }
            return this.formatTime(time);
        },
        formatTime(time) {
            return window.formatTime(time)
        },
    },

    created() {
        this.id = this.$route.params.id;
    },
};
</script>
