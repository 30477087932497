<template>
    <div class="login">
        <card title="Prihlásenie">
            <form
                :model="form"
                ref="form"
                class="form"
                @submit.prevent="handleSubmit()"
            >
                <input type="hidden" name="_token" :value="csrf" />
                <div class="form-item form-item--full">
                    <input
                        type="email"
                        id="email"
                        class="input"
                        placeholder="Email"
                        v-model="form.email"
                        required
                    />
                </div>
                <div class="form-item form-item--full">
                    <input
                        type="password"
                        id="password"
                        class="input"
                        placeholder="Heslo"
                        v-model="form.password"
                        required
                    />
                </div>
                <div class="form-item form-item--full">
                    <label class="checkbox">
                        <input class="checkbox__input" type="checkbox" v-model="form.remember"/>
                        <span class="checkbox__in">
                            <span class="checkbox__tick"> </span>
                            <span class="checkbox__text">Zapamätaj si ma</span>
                        </span>
                    </label>
                </div>
                <div class="form-buttons">
                    <button class="btn btn_purple btn_wide" :disabled="loading">
                        <span class="loader" v-if="loading"></span>
                        <span v-else>Prihlásiť</span>
                    </button>
                    <!-- <el-link :underline="false">Zabudnuté heslo?</el-link> -->
                </div>
            </form>
        </card>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            loading: false,
            form: {
                email: "admin@admin.com",
                password: "admin",
                remember: false,
            },
            remember_me: false,
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
        };
    },
    methods: {
        ...mapActions({
            signIn: "auth/login",
        }),
        async login() {
            this.loading = true;
            await this.$axios
                .get("/sanctum/csrf-cookie")
                .then((response) => {
                    this.$axios
                        .post("/api/login", this.form)
                        .then(({ data }) => {
                            if (data.status) {
                                this.signIn();
                            } else {
                                this.$toast.error(data.data);
                                this.loading = false;
                            }
                        })
                        .catch(({ response: { data } }) => {
                            this.$toast.error(data.message);
                            this.loading = false;
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        handleSubmit() {
            window.localStorage.setItem('remember', this.remember_me);
            this.login();
            // console.log(this.remember_me);
        },
    },
};
</script>

<style lang="scss" scoped>
.login {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 100vh;
    max-width: 27rem;
    margin: 0 auto;
    padding: 0 1rem;

    .card__title {
        text-align: center;
        font-size: 1.7rem;
    }
}
</style>
