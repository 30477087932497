<template>
    <card v-if="!loading">
        <custom-table :data="emails">
            <template v-slot:head>
                <div
                    class="table__cell"
                    :class="'table__cell__' + column.class"
                    v-for="(column, index) in columns"
                    :key="index"
                >
                    {{ column.name }}
                </div>
            </template>
            <template v-slot:body>
                <div
                    class="table__row"
                    v-for="(email, index) in emails"
                    :key="email.id"
                >

                    <div class="table__cell table__cell__from">
                        <router-link
                            :to="{
                            name: 'emails.get',
                            params: { id: email.id },
                        }"
                            class="table__from"
                        >
                            {{ email.from }}
                        </router-link>
                    </div>
                    <div class="table__cell table__cell__subject">
                        <router-link
                            :to="{
                            name: 'emails.get',
                            params: { id: email.id },
                        }"
                            class="table__subject"
                        >
                            {{ email.subject }}
                        </router-link>
                    </div>
                    <div class="table__cell table__cell__text">
                        <router-link
                            :to="{
                            name: 'emails.get',
                            params: { id: email.id },
                        }"
                            class="table__text"
                            v-html="email.text"
                        >
                        </router-link>
                    </div>
                    <div class="table__cell table__cell__date">
                        <router-link
                            :to="{
                            name: 'emails.get',
                            params: { id: email.id },
                        }"
                            class="table__text"
                        >
                            {{ email.date }}
                        </router-link>
                    </div>
                    <div class="table__cell table__cell__read">
                        <span class="table__read">
                             <font-awesome-icon
                                 v-if="email.read"
                                 class="icon check"
                                 :icon="['fa','check']"
                             ></font-awesome-icon>
                            <font-awesome-icon v-else
                                class="icon times"
                                :icon="['fa','times']"
                            ></font-awesome-icon>
                        </span>
                    </div>
                    <div class="table__cell table__cell__read">
                         <span class="table__read">
                             <font-awesome-icon
                                 v-if="email.task_created"
                                 class="icon check"
                                 :icon="['fa','check']"
                             ></font-awesome-icon>
                            <font-awesome-icon v-else
                                               class="icon times"
                                               :icon="['fa','times']"
                            ></font-awesome-icon>
                        </span>
                    </div>
                </div>
            </template>
        </custom-table>
    </card>
    <loader v-else></loader>
</template>

<script>
import CustomTable from "../../components/Table";
import eventBus from "../../eventBus";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
library.add(faCheck)
library.add(faTimes)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default {
    name: "EmailsList",
    components: {CustomTable, FontAwesomeIcon},
    data() {
        return {
            emails: [],
            loading: true,
            columns: [
                { name: "Od", class: "from" },
                { name: "Predmet", class: "subject" },
                { name: "Text", class: "text" },
                { name: "Date", class: "date" },
                { name: "Prečítané", class: "read" },
                { name: "Vybavené", class: "task_created" },
            ],
        }


    },
    methods: {
        async fetchEmails() {

            try {
                const response = await this.$axios.get(
                    `/api/emails/list`
                );
                const responseData = response.data;
                console.log(responseData);
                this.emails = responseData;
                this.loading = false;
            } catch (error) {
                console.log("error");
            }
        },
},
    mounted() {
        this.fetchEmails();
        eventBus.$on('emails', () => { this.fetchEmails() });
    },
    beforeUnmount() {
        eventBus.$off('emails');
    },

}
</script>

<style scoped>

</style>
