<template>
    <transition name="child" mode="out-in">
        <row v-if="!loading.tasks && !loading.projects && !loading.clients">
            <column>
                <card title="Dnešné úlohy">
                    <table-tasks :tasks="tasks"></table-tasks>
                    <template v-slot:footer v-if="count.tasks > count.default">
                        <router-link
                            :to="{ name: 'tasks' }"
                            class="btn btn_black"
                            >Zobraziť všetky</router-link
                        >
                    </template>
                </card>
            </column>
            <column>
                <card title="Projekty">
                    <table-projects :projects="projects"></table-projects>
                    <template
                        v-slot:footer
                        v-if="count.projects > count.default"
                    >
                        <router-link
                            :to="{ name: 'projects' }"
                            class="btn btn_black"
                            >Zobraziť všetky</router-link
                        >
                    </template>
                </card>
            </column>
            <column>
                <card title="Klienti">
                    <table-clients :clients="clients"></table-clients>
                    <template
                        v-slot:footer
                        v-if="count.clients > count.default"
                    >
                        <router-link
                            :to="{ name: 'clients' }"
                            class="btn btn_black"
                            >Zobraziť všetky</router-link
                        >
                    </template>
                </card>
            </column>
        </row>
        <loader v-else></loader>
    </transition>
</template>

<script>
import TableTasks from "../../components/Table/TableTasks.vue";
import TableProjects from "../../components/Table/TableProjects.vue";
import TableClients from "../../components/Table/TableClients.vue";
import eventBus from "../../eventBus";
export default {
    components: { TableTasks, TableProjects, TableClients },
    data() {
        return {
            loading: {
                tasks: true,
                projects: true,
                clients: true,
            },
            tasks: [],
            projects: [],
            clients: [],
            count: {
                default: 5,
                tasks: 0,
                projects: 0,
                clients: 0,
            },
        };
    },
    mounted() {
        eventBus.$on('tasks', (event)=> {
            this.fetchTasks();
        });
        eventBus.$on('projects', (event)=> {
            this.fetchProjects();
        });
        eventBus.$on('clients', (event)=> {
            this.fetchClients();
        });
    },
    beforeUnmount() {
        eventBus.$off('tasks');
        eventBus.$off('projects');
        eventBus.$off('clients');
    },
    methods: {
        async fetchTasks() {
            try {
                const response = await this.$axios.get("/api/tasks/list/today");
                const responseData = response.data;

                this.tasks = responseData.data.slice(0, this.count.default);
                this.count.tasks = responseData.data.length;

                this.loading.tasks = false;
            } catch (error) {
                console.log("error");
            }
        },
        async fetchProjects() {
            try {
                const response = await this.$axios.get("/api/projects");
                const responseData = response.data;

                this.projects = responseData.data.slice(0, this.count.default);
                this.count.projects = responseData.data.length;

                this.loading.projects = false;
            } catch (error) {
                console.log("error");
            }
        },
        async fetchClients() {
            try {
                const response = await this.$axios.get("/api/clients");
                const responseData = response.data;

                this.clients = responseData.data.slice(0, this.count.default);
                this.count.clients = responseData.data.length;

                this.loading.clients = false;
            } catch (error) {
                console.log("error");
            }
        },
    },
    created() {
        this.fetchTasks();
        this.fetchProjects();
        this.fetchClients();
    },
};
</script>
