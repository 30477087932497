<template>
    <custom-table :data="users">

        <template v-slot:head>
            <div
                class="table__cell"
                :class="'table__cell--' + column.class"
                v-for="(column, index) in columns"
                :key="index"
            >
                {{ column.name }}
            </div>

        </template>
        <template v-slot:body>
            <div id="confirm"></div>
            <div
                class="table__row"
                v-for="(user, index) in users"
                :key="user.id"
                :class="{ active: activeTableRow === index }"
            >
                <div class="table__cell table__cell--name">
                    <avatar :name="user.name"></avatar>
                    <router-link
                        :to="{
                            name: 'users.detail',
                            params: { id: user.id },
                        }"
                        class="table__title"
                    >
                        {{ user.name }}
                    </router-link>
                </div>
                <div class="table__cell table__cell--roles">
                    <role-tag
                        v-for="role in user.roles"
                        :key="role.id"
                        :title="role.title"
                        :typeId="role.id"
                    ></role-tag>
                </div>
                <div class="table__cell table__cell--email">
                    <span class="table__text">
                        {{ user.email }}
                    </span>
                </div>
                <div class="table__cell table__cell--operations">
                    <dropdown v-if="permission === 1">
                        <template v-slot:methods>
                            <router-link
                                :to="{
                                    name: 'users.edit',
                                    params: { id: user.id },
                                }"
                                class="dropdown__link"
                            >
                                Upraviť
                            </router-link>
                            <router-link
                                :to="{
                                    name: 'users.detail',
                                    params: { id: user.id },
                                }"
                                class="dropdown__link"
                            >
                                Zobraziť
                            </router-link>
                            <button
                                class="dropdown__link"
                                @click="handleDelete(user.id, index)"
                                v-if="id !== '4'"
                            >
                                Vymazať
                            </button>

                            <button
                                class="dropdown__link"
                                @click="handleRestore(user.id, index)"
                                v-if="id === '4'"
                            >
                                Obnoviť
                            </button>
                        </template>
                    </dropdown>
                </div>
            </div>
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "./index.vue";
import RoleTag from "../RoleTag/index.vue";
import {useConfirm} from 'v3confirm';
export default {
    props: ["users", "projectId"],
    components: { CustomTable, RoleTag,  },
    data() {
        return {
            id: null,
            permission: this.$store.state.auth.permission,
            columns: [
                { name: "Meno", class: "name" },
                { name: "Rola", class: "roles" },
                { name: "Email", class: "email" },
                { name: "Operácie", class: "operations" },
            ],
            activeTableRow: null,
        };
    },
    methods: {
        handleDelete(id, index) {

            if( confirm('Naozaj chcete odstrániť používatela z projektu?') == true) {
                this.activeTableRow = index;
                this.$axios
                .delete(`/api/projects/${this.projectId}/user/${id}`)
                .then((response) => {
                               this.$toast.success("Používateľ bol úspešne vymazaný");
                               this.users.splice(index, 1);
                           })
                .catch((error) => {
                               this.$toast.error("Používateľa sa nepodarilo vymazať");
                           })
                .then(() => {
                               this.activeTableRow = null;
                });
            }
            // this.$bvModal.msgBoxConfirm('Naozaj chcete odstrániť používatela z projektu?', {
            //     title: 'Odstrániť',
            //     size: 'sm',
            //     buttonSize: 'sm',
            //     okVariant: 'danger',
            //     okTitle: 'Áno',
            //     cancelTitle: 'Nie',
            //     footerClass: 'p-2',
            //     hideHeaderClose: false,
            //     centered: true
            // })
            //     .then(value => {
            //         console.log(value);

                     // this.activeTableRow = index;
                     // this.$axios
                     // .delete(`/api/projects/${this.projectId}/user/${id}`)
                     // .then((response) => {
                     //                this.$toast.success("Používateľ bol úspešne vymazaný");
                     //                this.users.splice(index, 1);
                     //            })
                     // .catch((error) => {
                     //                this.$toast.error("Používateľa sa nepodarilo vymazať");
                     //            })
                     // .then(() => {
                     //                this.activeTableRow = null;
                     // });

                // })
                // .catch(err => {
                //     // An error occurred
                // })

        },
        handleRestore(id, index) {
            this.activeTableRow = index;

            this.$axios
                .post(`/api/users/restore/${id}`)
                .then((response) => {
                    this.$toast.success("Používateľ bol úspešne obnovený");
                    this.users.splice(index, 1);
                })
                .catch((error) => {
                    this.$toast.error("Používateľ sa nepodarilo obnoviť");
                })
                .then(() => {
                    this.activeTableRow = null;
                });
        },
    },
    created() {
        this.id = this.$route.params.id;
    },
};
</script>
